export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const GET_SELECTED_MESSAGE_SUCCESS = 'GET_SELECTED_MESSAGE_SUCCESS';
export const GET_SELECTED_MESSAGE = 'GET_SELECTED_MESSAGES';
export const GET_SELECTED_MESSAGE_FAILED = 'GET_SELECTED_MESSAGES_FAILED';

export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';
export const POST_MESSAGE = 'POST_MESSAGE';
export const POST_MESSAGE_FAILED = 'POST_MESSAGE_FAILED';

export const PUT_READ_MESSAGE = 'PUT_READ_MESSAGE';
export const PUT_READ_MESSAGE_SUCCESS = 'PUT_READ_MESSAGE_SUCCESS';
export const PUT_READ_MESSAGE_FAILED = 'PUT_READ_MESSAGE_FAILED';

export const TAKE_BY_CSAGENT_SUCCESS = 'TAKE_BY_CSAGENT_SUCCESS';
export const TAKE_BY_CSAGENT = 'TAKE_BY_CSAGENT';
export const TAKE_BY_CSAGENT_FAILED = 'TAKE_BY_CSAGENT_FAILED';

export const CLOSE_CHAT_BY_CSAGENT_SUCCESS = 'CLOSE_CHAT_BY_CSAGENT_SUCCESS';
export const CLOSE_CHAT_BY_CSAGENT = 'CLOSE_CHAT_BY_CSAGENT';
export const CLOSE_CHAT_BY_CSAGENT_FAILED = 'CLOSE_CHAT_BY_CSAGENT_FAILED';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';

export const SET_IMAGE_URL = 'SET_IMAGE_URL';

export const POST_LIVE_CHAT_SETTINGS_SUCCESS =
  'POST_LIVE_CHAT_SETTINGS_SUCCESS';
export const POST_LIVE_CHAT_SETTINGS = 'POST_LIVE_CHAT_SETTINGS';
export const POST_LIVE_CHAT_SETTINGS_FAILED = 'POST_LIVE_CHAT_SETTINGS_FAILED';

export const GET_DETAIL_LIVE_CHAT_SETTING_SUCCESS =
  'GET_DETAIL_LIVE_CHAT_SETTING_SUCCESS';
export const GET_DETAIL_LIVE_CHAT_SETTING = 'GET_DETAIL_LIVE_CHAT_SETTING';
export const GET_DETAIL_LIVE_CHAT_SETTING_FAILED =
  'GET_DETAIL_LIVE_CHAT_SETTING_FAILED';

export const PUT_DETAIL_LIVE_CHAT_SETTING_SUCCESS =
  'PUT_DETAIL_LIVE_CHAT_SETTING_SUCCESS';
export const PUT_DETAIL_LIVE_CHAT_SETTING = 'PUT_DETAIL_LIVE_CHAT_SETTING';
export const PUT_DETAIL_LIVE_CHAT_SETTING_FAILED =
  'PUT_DETAIL_LIVE_CHAT_SETTING_FAILED';

export const POST_CS_AGENT_STATISTIC = 'POST_CS_AGENT_STATISTIC';
export const POST_CS_AGENT_STATISTIC_SUCCESS =
  'POST_CS_AGENT_STATISTIC_SUCCESS';
export const POST_CS_AGENT_STATISTIC_FAILED = 'POST_CS_AGENT_STATISTIC_FAILED';

export const POST_CS_AGENT_STATISTIC_BY_ID = 'POST_CS_AGENT_STATISTIC_BY_ID';
export const POST_CS_AGENT_STATISTIC_BY_ID_SUCCESS =
  'POST_CS_AGENT_STATISTIC_BY_ID_SUCCESS';
export const POST_CS_AGENT_STATISTIC_BY_ID_FAILED =
  'POST_CS_AGENT_STATISTIC_BY_ID_FAILED';

export const POST_CS_AGENT_SUMMARY = 'POST_CS_AGENT_SUMMARY';
export const POST_CS_AGENT_SUMMARY_SUCCESS = 'POST_CS_AGENT_SUMMARY_SUCCESS';
export const POST_CS_AGENT_SUMMARY_FAILED = 'POST_CS_AGENT_SUMMARY_FAILED';

export const POST_CS_AGENT_MONITORING = 'POST_CS_AGENT_MONITORING';
export const POST_CS_AGENT_MONITORING_SUCCESS =
  'POST_CS_AGENT_MONITORING_SUCCESS';
export const POST_CS_AGENT_MONITORING_FAILED =
  'POST_CS_AGENT_MONITORING_FAILED';

export const POST_CS_AGENT_FORCE_LOGOUT = 'POST_CS_AGENT_FORCE_LOGOUT';
export const POST_CS_AGENT_FORCE_LOGOUT_SUCCESS =
  'POST_CS_AGENT_FORCE_LOGOUT_SUCCESS';
export const POST_CS_AGENT_FORCE_LOGOUT_FAILED =
  'POST_CS_AGENT_FORCE_LOGOUT_FAILED';

export const POST_CS_AGENT_HISTORY = 'POST_CS_AGENT_HISTORY';
export const POST_CS_AGENT_HISTORY_SUCCESS = 'POST_CS_AGENT_HISTORY_SUCCESS';
export const POST_CS_AGENT_HISTORY_FAILED = 'POST_CS_AGENT_HISTORY_FAILED';

export const RESET_STATE = 'RESET_STATE';
