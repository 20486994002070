import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import { Button } from 'reactstrap';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  getVendor as getVendorProps,
  putVendorStatus as putVendorStatusProps,
  resetState as resetStateProps,
} from '@myrepublic-wokumo/wokumoAction';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

// function OpenConfirm(props) {
//   const {
//     handleClose,
//     handleClickOpen,
//     open
//   } = props;

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description">
//         <DialogTitle id="alert-dialog-title">
//           {'Konfirmasi hapus data'}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Apakah anda yakin akan menghapus data?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Tidak
//           </Button>
//           <Button onClick={handleClickOpen} color="primary" autoFocus>
//             Ya
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

function Vendor(props) {
  const {
    getVendor,
    getVendorResponse,
    putVendorStatus,
    putVendorStatusResponse,
    pagination,
    resetState,
    PaginationRowHandler,
    PaginationHandler,
    getVendorFetch,
  } = props;
  const [id, setid] = useState(0);
  const history = useHistory();

  const getDataTable = (_pagination = null) => {
    getVendor({
      data: { ..._pagination, filter: { roleName: 'Vendor' } },
    });
  };

  const handleActive = (row) => {
    putVendorStatus({ id: row.id, body: { statusActive: !row.statusActive } });
  };

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };

  const handleAdd = () => {
    resetState({});
    history.push('/create-vendor');
  };

  const handleEdit = (row) => {
    history.push({
      pathname: `/detail-vendor/${row.id}`,
      state: { pageType: 'update' },
    });
  };

  const handleDetail = (row) => {
    history.push({
      pathname: `/detail-vendor/${row.id}`,
      state: { pageType: 'detail' },
    });
  };

  const modalShowData = () => {
    history.push('/create-vendor');
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  useEffect(() => {
    resetState();
    PaginationHandler({
      ...defaultPaging,
    });
    PaginationRowHandler({ ...defaultPaginationRowHandler });
    getDataTable(defaultPaging);
  }, []);

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => handleAdd()}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <WrapContainerBased
        key={id}
        withTable
        withPagination
        totalRecord={getVendorResponse?.totalElements || 0}
        totalPage={pagination.limit}
        wrapTitle={'Vendor'}
        data={getVendorResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive, handleDetail)}
        withButton={buttonComponent()}
        handleAddData={modalShowData}
        handleChangePage={handleChangePage}
        showPerPage={1}
        isLoading={getVendorFetch}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  getVendorResponse: state.wokumo.getVendorResponse,
  putVendorStatusResponse: state.wokumo.putVendorStatusResponse,
  getVendorFetch: state.wokumo.getVendorFetch,
});

const mapDispatchToProps = {
  resetState: () => resetStateProps(),
  getVendor: (payload) => getVendorProps(payload),
  putVendorStatus: (payload) => putVendorStatusProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
