export const usersInitialState = {
  postUsersFetch: false,
  postUsersParams: {},
  postUsersResponse: [],
  postUsersError: {
    message: '',
  },
  teknisiVendorFetch: false,
  teknisiVendorParams: {},
  teknisiVendorResponse: [],
  teknisiVendorError: {
    message: '',
  },

  putUserStatusFetch: false,
  putUserStatusParams: {},
  putUserStatusResponse: [],
  putUserStatusError: {
    message: '',
  },

  createUserFetch: false,
  createUserParams: {},
  createUserResponse: [],
  createUserError: {
    message: '',
  },

  updateUserFetch: false,
  updateUserParams: {},
  updateUserResponse: [],
  updateUserError: {
    message: '',
  },

  deleteUserPermissionFetch: false,
  deleteUserPermissionParams: {},
  deleteUserPermissionResponse: [],
  deleteUserPermissionError: {
    message: '',
  },

  getUserPermissionListFetch: false,
  getUserPermissionListParams: {},
  getUserPermissionListResponse: [],
  getUserPermissionListError: {
    message: '',
  },

  detailUserFetch: false,
  detailUserParams: {},
  detailUserResponse: [],
  detailUserError: {
    message: '',
  },

  getUserRolesFetch: false,
  getUserRolesParams: {},
  getUserRolesResponse: [],
  getUserRolesError: {
    message: '',
  },

  getTechnicianRatingFetch: false,
  getTechnicianRatingParams: {},
  getTechnicianRatingResponse: [],
  getTechnicianRatingError: {
    message: '',
  },
  listVendor: [],
};
