import { call, put, takeLatest, select } from 'redux-saga/effects';
import { RESPONSE_STATUS } from '@myrepublic-config/env';
import {
  getVendor,
  getWorkOrder,
  postWorkOrder,
  putVendorStatus,
  postVendor,
  putVendor,
  getVendorDetail,
  postVendorStatistics,
} from '@myrepublic-wokumo/wokumoApi';
import {
  getVendorFailed,
  getVendorSuccess,
  getWorkOrderFailed,
  getWorkOrderSuccess,
  postWorkOrderFailed,
  postWorkOrderSuccess,
  putVendorStatusFailed,
  putVendorStatusSuccess,
  postVendorFailed,
  postVendorSuccess,
  putVendorSuccess,
  putVendorFailed,
  getVendorDetailFailed,
  getVendorDetailSuccess,
  postVendorStatisticsFailed,
  postVendorStatisticsSuccess,
} from '@myrepublic-wokumo/wokumoAction';
import * as CONST from '@myrepublic-wokumo/wokumoConstant';
import { toast } from 'react-toastify';

function* getVendorSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getVendor, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getVendorSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getVendorFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getVendorFailed(error.response));
        break;
      default:
    }
  }
}

function* getWorkOrderSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getWorkOrder, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getWorkOrderSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getWorkOrderFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getWorkOrderFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getWorkOrderFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getWorkOrderFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getWorkOrderFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getWorkOrderFailed(error.response));
        break;
      default:
    }
  }
}

function* postWorkOrderSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postWorkOrder, {
      accessToken,
      ...params.payload,
    });
    console.log('rsponse', response);

    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postWorkOrderSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postWorkOrderFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postWorkOrderFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postWorkOrderFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postWorkOrderFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postWorkOrderFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postWorkOrderFailed(error.response));
        break;
      default:
    }
  }
}

function* putVendorStatusSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putVendorStatus, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putVendorStatusSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putVendorStatusFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putVendorStatusFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putVendorStatusFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putVendorStatusFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putVendorStatusFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putVendorStatusFailed(error.response));
        break;
      default:
    }
  }
}

function* postVendorSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postVendor, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postVendorSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postVendorFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postVendorFailed(error.response));
        break;
      default:
    }
  }
}

function* putVendorSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(putVendor, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(putVendorSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(putVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(putVendorFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(putVendorFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(putVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(putVendorFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(putVendorFailed(error.response));
        break;
      default:
    }
  }
}

function* getVendorDetailSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(getVendorDetail, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(getVendorDetailSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(getVendorDetailFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(getVendorDetailFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(getVendorDetailFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(getVendorDetailFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(getVendorDetailFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(getVendorDetailFailed(error.response));
        break;
      default:
    }
  }
}

function* postVendorStatisticsSaga(params) {
  try {
    const accessToken = yield select((state) => state.session.token);
    const response = yield call(postVendorStatistics, {
      accessToken,
      ...params.payload,
    });
    switch (response.status) {
      case RESPONSE_STATUS.SUCCESS:
        yield put(postVendorStatisticsSuccess(response.data.data));
        break;
      case RESPONSE_STATUS.ERROR:
        yield put(postVendorStatisticsFailed(response.data));
        break;
      case RESPONSE_STATUS.NEED_ACTION:
        yield put(postVendorStatisticsFailed(response.data));
        break;
      case RESPONSE_STATUS.UNAUTHORIZED:
        yield put(postVendorStatisticsFailed(response.data));
        break;
      case RESPONSE_STATUS.NOT_ALLOW:
        yield put(postVendorStatisticsFailed(response.data));
        break;
      default:
    }
  } catch (error) {
    switch (error) {
      case RESPONSE_STATUS.ERROR:
        yield put(postVendorStatisticsFailed(error.response));
        break;
      case RESPONSE_STATUS.FORBIDDEN:
        yield put(postVendorStatisticsFailed(error.response));
        break;
      case RESPONSE_STATUS.BAD_REQUEST:
        yield put(postVendorStatisticsFailed(error.response));
        break;
      default:
    }
  }
}

export default [
  takeLatest(CONST.GET_VENDOR, getVendorSaga),
  takeLatest(CONST.GET_WORK_ORDER, getWorkOrderSaga),
  takeLatest(CONST.POST_WORK_ORDER, postWorkOrderSaga),
  takeLatest(CONST.PUT_VENDOR_STATUS, putVendorStatusSaga),
  takeLatest(CONST.POST_VENDOR, postVendorSaga),
  takeLatest(CONST.PUT_VENDOR, putVendorSaga),
  takeLatest(CONST.GET_VENDOR_DETAIL, getVendorDetailSaga),
  takeLatest(CONST.POST_VENDOR_STATISTIC, postVendorStatisticsSaga),
];
