import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { postVendor as postVendorProps } from '@myrepublic-wokumo/wokumoAction';
import { DropzoneArea } from 'material-ui-dropzone';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function CreateVendor(props) {
  const { postVendor, postVendorResponse, postVendorError } = props;
  const [data, setData] = useState({});
  //   const [description, setDescription] = useState({ value: '' });
  //   const [openErrorInfo, setOpenErrorInfo] = useState(false);
  //   const [errorInfo, setErrorInfo] = useState('');
  //   const [userPermissions, setUserPermissions] = useState({ data: [] });
  //   const [permissionOptions, setPermissionOptions] = useState([]);
  const history = useHistory();
  //   const [key, setKey] = useState(0);

  //   let closeToast = (event, reason) => {
  //     if (reason === 'clickaway') {
  //       return;
  //     }
  //     setOpenErrorInfo(false);
  //   };

  //   const dataVal = () => {
  //     const value = userPermissions.data;
  //     let data = [];
  //     if (typeof value === 'object') {
  //       data = value.map((userPermission, index) => {
  //         console.log('userPermission <<<< ', userPermission);
  //         if (userPermission.id === undefined) {
  //           return {
  //             permissionId: userPermission.permissionId,
  //           };
  //         }
  //         return {
  //           id: userPermission.id,
  //           permissionId: userPermission.permissionId,
  //         };
  //       });
  //       return values(data);
  //     }
  //   };

  //   const handleOpenErrorInfo = () => {
  //     setOpenErrorInfo(true);
  //   };

  //   const validateCreateForm = () => {
  //     let userPermissionList = dataVal();
  //     if (data?.permissionId === '') {
  //       setErrorInfo('Nama Permission harus diisi');
  //       handleOpenErrorInfo();
  //       return false;
  //     } else {
  //       userPermissionList.map((items) => {
  //         if (
  //           items.permissionId === undefined ||
  //           items.permissionId === 'undefined' ||
  //           items.permissionId === ''
  //         ) {
  //           setErrorInfo('Lengkapi semua field data pada permission list');
  //           handleOpenErrorInfo();
  //           return false;
  //         }
  //       });
  //     }
  //     return true;
  //   };
  const handleOnSave = () => {
    // if (validateCreateForm() === true) {
    //   const sendData = {
    //     ...data,
    //     userPermissionList: userPermissions.data,
    //   };
    console.log('SEND DATA', data);

    postVendor({
      ...data,
    });
  };

  useEffect(() => {
    console.log('postVendorResponse', postVendorResponse);
    if (postVendorResponse?.id != undefined) {
      toast.success(postVendorResponse?.message);
      setTimeout(() => {
        history.replace('/vendor');
      }, 3000);
    }
  }, [postVendorResponse]);

  useEffect(() => {
    console.log('postVendorResponse', postVendorResponse);
  }, [postVendorResponse]);

  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>
          <h5>
            <b>Create Vendor</b>
          </h5>
        </CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Company Name</h6>
                <Form.Control
                  value={data?.companyName}
                  type="text"
                  placeholder="Company Name"
                  onChange={(e) =>
                    setData({ ...data, companyName: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Handphone</h6>
                <Form.Control
                  value={data?.Handphone}
                  type="text"
                  placeholder="Handphone"
                  onChange={(e) =>
                    setData({ ...data, handphone: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>PIC</h6>
                <Form.Control
                  value={data?.pic}
                  type="text"
                  placeholder="PIC"
                  onChange={(e) => setData({ ...data, pic: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>Telephone</h6>
                <Form.Control
                  value={data?.ticketCategory}
                  type="text"
                  placeholder="Telephone"
                  onChange={(e) =>
                    setData({ ...data, telephone: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Address</h6>
                <Form.Control
                  value={data?.address}
                  type="text"
                  placeholder="Address"
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>Province</h6>
                <Form.Control
                  value={data?.ticketCategory}
                  type="number"
                  placeholder="Province"
                  onChange={(e) =>
                    setData({ ...data, province: Number(e.target.value) })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Regency</h6>
                <Form.Control
                  value={data?.regency}
                  type="number"
                  placeholder="Regency"
                  onChange={(e) =>
                    setData({ ...data, regency: Number(e.target.value) })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>District</h6>
                <Form.Control
                  value={data?.vendorId}
                  type="number"
                  placeholder="District"
                  onChange={(e) =>
                    setData({ ...data, district: Number(e.target.value) })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Village</h6>
                <Form.Control
                  value={data?.village}
                  type="number"
                  placeholder="Village"
                  onChange={(e) =>
                    setData({ ...data, village: Number(e.target.value) })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Area</h6>
                <Form.Control
                  value={data?.area}
                  type="number"
                  placeholder="Area"
                  onChange={(e) =>
                    setData({ ...data, area: [Number(e.target.value)] })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            className="bg-secondary"
            onClick={() => history.push('/vendor')}>
            Kembali
          </Button>
          <Button
            className="bg-primary"
            onClick={handleOnSave}
            disabled={false}>
            Simpan
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  postVendorResponse: state.wokumo.postVendorResponse,
  postVendorError: state.wokumo.postVendorError,
});
const mapDispatchToProps = {
  postVendor: (payload) => postVendorProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVendor);
