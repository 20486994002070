import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import {
  postUsers as postUsersProps,
  putUserStatus as putUserStatusProps,
  postUsersReset as postUsersResetProps,
  getListVendor,
} from '@myrepublic-users/usersAction';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { FilterButton } from '@myrepublic-components/index';
import FilterMenu from './components/FilterMenu';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

function Technician(props) {
  const {
    pagination,
    postUsers,
    postUsersReset,
    postUsersResponse,
    postUsersFetch,
    putUserStatus,
    // putUserStatusResponse,
    PaginationRowHandler,
    PaginationHandler,
    putUserStatusFetch,
  } = props;
  const [filter, setFilter] = useState({});
  const [active, setActive] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { listVendor } = useSelector((state) => state.user);

  const listVendorOptions = listVendor?.map((item) => {
    const [id] = Object.keys(item);
    const str = item[Object.keys(item)];

    // for capitalize each first letter
    const arr = str.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(' ');
    return {
      vendorId: id,
      label: str2,
      value: item[Object.keys(item)],
    };
  });

  const getDataTable = (_pagination = null) => {
    postUsers({
      data: {
        ..._pagination,
        filter: { roleName: 'Technician', ...filter },
      },
    });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  // useEffect(() => {
  //   // !prevPaging &&
  //   //   pagination.limit === 10 &&
  //   //   pagination.offset === 0 &&
  //   //   postUsers({
  //   //     data: {
  //   //       ...defaultPaging,
  //   //       filter: { roleName: 'Technician' },
  //   //     },
  //   //   });
  //   // eslint-disable-next-line
  // }, []);

  // const prevPaging = usePrevious(pagination);

  // useEffect(() => {
  //   setid((prev) => {
  //     return prev + Math.random();
  //   });
  //   prevPaging &&
  //     prevPaging?.limit !== pagination.limit &&
  //     pagination.offset === 0 &&
  //     getDataTable();
  //   prevPaging &&
  //     PaginationRowHandler({
  //       value: pagination.limit,
  //       label: pagination.limit,
  //     });
  //   // eslint-disable-next-line
  // }, [pagination.limit]);

  // useEffect(() => {
  //   prevPaging && prevPaging?.limit === pagination.limit && getDataTable();
  //   // eslint-disable-next-line
  // }, [pagination.offset, putUserStatusResponse.data]);

  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // }

  const handleEdit = (row) => {
    history.push(`/detail-teknisi/${row.id}`);
  };

  const handleApprove = (row) => {
    putUserStatus({ id: row.id, payload: { active: !row.active } });
    setActive(!active);
  };

  const handleGenerateList = (filter) => {
    postUsers({
      data: { ...defaultPaging, filter: { roleName: 'Technician', ...filter } },
    });
  };

  useEffect(() => {
    postUsersReset();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    dispatch(getListVendor());
    getDataTable(defaultPaging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putUserStatusFetch]);

  const withFilterButton = () => {
    return (
      <FilterButton>
        <FilterMenu
          filter={filter}
          setFilter={setFilter}
          isLoading={postUsersFetch}
          listVendorOptions={listVendorOptions}
          handleGenerateList={handleGenerateList}
        />
      </FilterButton>
    );
  };

  return (
    <>
      <WrapContainerBased
        key={999999998888}
        withTable
        withPagination
        withFilterButton={withFilterButton()}
        totalRecord={postUsersResponse?.data?.totalElements || 0}
        totalPage={pagination?.limit}
        data={postUsersResponse?.data?.content}
        isLoading={postUsersFetch}
        wrapTitle={'Teknisi'}
        columns={columns(handleEdit, null, handleApprove, pagination)}
        handleChangePage={handleChangePage}
        showPerPage={1}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postUsersResponse: state.user.postUsersResponse,
  putUserStatusResponse: state.user.putUserStatusResponse,
  getUserRolesResponse: state.user.getUserRolesResponse,
  postUsersFetch: state.user.postUsersFetch,
  putUserStatusFetch: state.user.putUserStatusFetch,
});

const mapDispatchToProps = {
  postUsers: (payload) => postUsersProps(payload),
  postUsersReset: (payload) => postUsersResetProps(payload),
  putUserStatus: (payload) => putUserStatusProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(Technician);
