import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'reactstrap';
import {
  postUsers as postUsersProps,
  putUserStatus as putUserStatusProps,
  resetState as resetStateProps,
  postUsersReset as postUsersResetProps,
} from '@myrepublic-users/usersAction';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CustomerJourney(props) {
  const {
    pagination,
    postUsers,
    postUsersReset,
    postUsersResponse,
    putUserStatus,
    putUserStatusResponse,
    resetState,
    PaginationRowHandler,
    PaginationHandler,
    postUsersFetch,
  } = props;
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [id, setid] = useState(0);
  const history = useHistory();

  const getDataTable = (_pagination = null) => {
    postUsers({
      data: { ..._pagination, filter: { roleName: 'Customer Journey' } },
    });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  // const handleOpenErrorInfo = () => {
  //   setOpenErrorInfo(true);
  // };

  // const handleClose = () => {
  //   setShowCreateModal(false);
  // };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    // deleteProduct({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };

  const handleDelete = (row) => {
    setOpenModalDelete(true);
  };

  const handleEdit = (row) => {
    resetState({});
    history.push('/update-customer-journey/' + row?.id);
  };

  const handleDetail = (row) => {
    resetState({});
    history.push('/detail-customer-journey/' + row?.id);
  };

  const handleHistory = (row) => {
    history.push('/livechat-statistik/' + row.id);
  };

  const handleActive = (row) => {
    putUserStatus({ id: row.id, payload: { active: !row.active } });
  };

  const handleAdd = () => {
    resetState({});
    history.push('/create-customer-journey');
  };

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={handleAdd}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WrapContainerBased
        key={id}
        withTable
        withPagination
        totalRecord={postUsersResponse?.data?.totalElements || 0}
        totalPage={pagination.limit}
        data={postUsersResponse?.data?.content}
        wrapTitle={'Customer Journey'}
        columns={columns(handleEdit, handleDetail, handleActive, handleHistory)}
        withButton={buttonComponent()}
        isLoading={postUsersFetch}
        handleChangePage={handleChangePage}
        showPerPage={1}>
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postUsersResponse: state.user.postUsersResponse,
  putUserStatusResponse: state.user.putUserStatusResponse,
  postUsersFetch: state.user.postUsersFetch,
});

const mapDispatchToProps = {
  postUsers: (payload) => postUsersProps(payload),
  postUsersReset: (payload) => postUsersResetProps(payload),
  putUserStatus: (payload) => putUserStatusProps(payload),
  resetState: () => resetStateProps(),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerJourney);
