import * as CONST from '@myrepublic-livechat/liveChatConstant';

export const getMessages = (payload) => ({
  type: CONST.GET_MESSAGES,
  payload,
});

export const getMessagesFailed = (payload) => ({
  type: CONST.GET_MESSAGES_FAILED,
  payload,
});

export const getMessagesSuccess = (payload) => ({
  type: CONST.GET_MESSAGES_SUCCESS,
  payload,
});

export const getSelectedMessage = (payload) => ({
  type: CONST.GET_SELECTED_MESSAGE,
  payload,
});

export const getSelectedMessageFailed = (payload) => ({
  type: CONST.GET_SELECTED_MESSAGE_FAILED,
  payload,
});

export const getSelectedMessageSuccess = (payload) => ({
  type: CONST.GET_SELECTED_MESSAGE_SUCCESS,
  payload,
});

export const takeByCsagent = (payload) => ({
  type: CONST.TAKE_BY_CSAGENT,
  payload,
});

export const takeByCsagentFailed = (payload) => ({
  type: CONST.TAKE_BY_CSAGENT_FAILED,
  payload,
});

export const takeByCsagentSuccess = (payload) => ({
  type: CONST.TAKE_BY_CSAGENT_SUCCESS,
  payload,
});

export const postMessage = (payload) => ({
  type: CONST.POST_MESSAGE,
  payload,
});

export const postMessageFailed = (payload) => ({
  type: CONST.POST_MESSAGE_FAILED,
  payload,
});

export const postMessageSuccess = (payload) => ({
  type: CONST.POST_MESSAGE_SUCCESS,
  payload,
});

export const putReadMessage = (payload) => ({
  type: CONST.PUT_READ_MESSAGE,
  payload,
});

export const putReadMessageFailed = (payload) => ({
  type: CONST.PUT_READ_MESSAGE_FAILED,
  payload,
});

export const putReadMessageSuccess = (payload) => ({
  type: CONST.PUT_READ_MESSAGE_SUCCESS,
  payload,
});

export const closeChatByCsagent = (payload) => ({
  type: CONST.CLOSE_CHAT_BY_CSAGENT,
  payload,
});

export const closeChatByCsagentFailed = (payload) => ({
  type: CONST.CLOSE_CHAT_BY_CSAGENT_FAILED,
  payload,
});

export const closeChatByCsagentSuccess = (payload) => ({
  type: CONST.CLOSE_CHAT_BY_CSAGENT_SUCCESS,
  payload,
});
export const uploadImage = (payload) => ({
  type: CONST.UPLOAD_IMAGE,
  payload,
});

export const uploadImageSuccess = (payload) => ({
  type: CONST.UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageFailed = (payload) => ({
  type: CONST.UPLOAD_IMAGE_FAILED,
  payload,
});

export const setImageUrl = (payload) => ({
  type: CONST.SET_IMAGE_URL,
  payload,
});

export const postLiveChatSettings = (payload) => ({
  type: CONST.POST_LIVE_CHAT_SETTINGS,
  payload,
});

export const postLiveChatSettingsFailed = (payload) => ({
  type: CONST.POST_LIVE_CHAT_SETTINGS_FAILED,
  payload,
});

export const postLiveChatSettingsSuccess = (payload) => ({
  type: CONST.POST_LIVE_CHAT_SETTINGS_SUCCESS,
  payload,
});

export const getDetailLiveChatSetting = (payload) => ({
  type: CONST.GET_DETAIL_LIVE_CHAT_SETTING,
  payload,
});

export const getDetailLiveChatSettingFailed = (payload) => ({
  type: CONST.GET_DETAIL_LIVE_CHAT_SETTING_FAILED,
  payload,
});

export const getDetailLiveChatSettingSuccess = (payload) => ({
  type: CONST.GET_DETAIL_LIVE_CHAT_SETTING_SUCCESS,
  payload,
});

export const putDetailLiveChatSetting = (payload) => ({
  type: CONST.PUT_DETAIL_LIVE_CHAT_SETTING,
  payload,
});

export const putDetailLiveChatSettingFailed = (payload) => ({
  type: CONST.PUT_DETAIL_LIVE_CHAT_SETTING_FAILED,
  payload,
});

export const putDetailLiveChatSettingSuccess = (payload) => ({
  type: CONST.PUT_DETAIL_LIVE_CHAT_SETTING_SUCCESS,
  payload,
});

export const postCSAgentChatStatistics = (payload) => ({
  type: CONST.POST_CS_AGENT_STATISTIC,
  payload,
});

export const postCSAgentChatStatisticsFailed = (payload) => ({
  type: CONST.POST_CS_AGENT_STATISTIC_FAILED,
  payload,
});

export const postCSAgentChatStatisticsSuccess = (payload) => ({
  type: CONST.POST_CS_AGENT_STATISTIC_SUCCESS,
  payload,
});

export const postCSAgentChatStatisticsById = (payload) => ({
  type: CONST.POST_CS_AGENT_STATISTIC_BY_ID,
  payload,
});

export const postCSAgentChatStatisticsByIdFailed = (payload) => ({
  type: CONST.POST_CS_AGENT_STATISTIC_BY_ID_FAILED,
  payload,
});

export const postCSAgentChatStatisticsByIdSuccess = (payload) => ({
  type: CONST.POST_CS_AGENT_STATISTIC_BY_ID_SUCCESS,
  payload,
});

export const postCSAgentChatSummary = (payload) => ({
  type: CONST.POST_CS_AGENT_SUMMARY,
  payload,
});

export const postCSAgentChatSummaryFailed = (payload) => ({
  type: CONST.POST_CS_AGENT_SUMMARY_FAILED,
  payload,
});

export const postCSAgentChatSummarySuccess = (payload) => ({
  type: CONST.POST_CS_AGENT_SUMMARY_SUCCESS,
  payload,
});

export const postCSAgentChatMonitoring = (payload) => ({
  type: CONST.POST_CS_AGENT_MONITORING,
  payload,
});

export const postCSAgentChatMonitoringFailed = (payload) => ({
  type: CONST.POST_CS_AGENT_MONITORING_FAILED,
  payload,
});

export const postCSAgentChatMonitoringSuccess = (payload) => ({
  type: CONST.POST_CS_AGENT_MONITORING_SUCCESS,
  payload,
});

export const postCSAgentForceLogout = (payload) => ({
  type: CONST.POST_CS_AGENT_FORCE_LOGOUT,
  payload,
});

export const postCSAgentForceLogoutFailed = (payload) => ({
  type: CONST.POST_CS_AGENT_FORCE_LOGOUT_FAILED,
  payload,
});

export const postCSAgentForceLogoutSuccess = (payload) => ({
  type: CONST.POST_CS_AGENT_FORCE_LOGOUT_SUCCESS,
  payload,
});

export const postCSAgentHistory = (payload) => ({
  type: CONST.POST_CS_AGENT_HISTORY,
  payload,
});

export const postCSAgentHistoryFailed = (payload) => ({
  type: CONST.POST_CS_AGENT_HISTORY_FAILED,
  payload,
});

export const postCSAgentHistorySuccess = (payload) => ({
  type: CONST.POST_CS_AGENT_HISTORY_SUCCESS,
  payload,
});

export const resetState = () => ({
  type: CONST.RESET_STATE,
});
