import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import {
  updateUser as updateUserProps,
  detailUser as detailUserProps,
  uploadImage as uploadImageProps,
  deleteUserPermission as deleteUserPermissionProps,
  getUserPermissionList as getUserPermissionListProps,
  getUserRoles as getUserRolesProps,
} from '@myrepublic-users/usersAction';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function UpdateCustomer(props) {
  const {
    updateUser,
    updateUserResponse,
    detailUser,
    detailUserResponse,
    deleteUserPermissionResponse,
    deleteUserPermission,
    getUserPermissionList,
    getUserPermissionListResponse,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    getUserRoles,
    getUserRolesResponse,
    icon,

    match: { params },
  } = props;
  const [data, setData] = useState({});
  const [description, setDescription] = useState({ value: '' });
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [errorInfo, setErrorInfo] = useState('');
  const [userPermissions, setUserPermissions] = useState({ data: [] });
  const [permissionOptions, setPermissionOptions] = useState([]);
  const history = useHistory();
  const [key, setKey] = useState(0);
  const [roleId, setRoleId] = useState();

  console.log('data in here is', data);

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const dataVal = () => {
    const value = userPermissions.data;
    let data = [];
    if (typeof value === 'object') {
      data = value.map((userPermission, index) => {
        if (userPermission.id === undefined) {
          return {
            permissionId: userPermission.permissionId,
          };
        }
        return {
          id: userPermission.id,
          permissionId: userPermission.permissionId,
        };
      });
      return values(data);
    }
  };

  const handleOpenErrorInfo = () => {
    setOpenErrorInfo(true);
  };

  const validateCreateForm = () => {
    let userPermissionList = dataVal();
    if (data?.permissionId === '') {
      setErrorInfo('Nama Permission harus diisi');
      handleOpenErrorInfo();
      return false;
    } else {
      userPermissionList.map((items) => {
        if (
          items.permissionId === undefined ||
          items.permissionId === 'undefined' ||
          items.permissionId === ''
        ) {
          setErrorInfo('Lengkapi semua field data pada permission list');
          handleOpenErrorInfo();
          return false;
        }
      });
    }
    return true;
  };
  const handleOnSave = () => {
    if (validateCreateForm() === true) {
      const sendData = {
        name: data.name,
        userPermissionList: dataVal(),
        devicePackageName: 'WEB',
        roleId: roleId,
      };
      console.log('SEND DATA', sendData);

      updateUser({
        payload: sendData,
        id: data.id,
      });
    }
  };

  const onUploadImage = (image) => {
    if (image?.length > 0) {
      uploadImage({ data: { image: image[0], type: 'PRODUCT' } });
    }
  };

  useEffect(() => {
    if (updateUserResponse?.code === 200) {
      toast.success(updateUserResponse?.message);
      setTimeout(() => {
        history.replace('/superadmin');
      }, 3000);
    }
  }, [updateUserResponse?.code]);

  useEffect(() => {
    if (detailUserResponse?.code === 200) {
      setData(detailUserResponse?.data);
      detailUserResponse?.data?.userPermissions?.map((i, index) => {
        userPermissions?.data.push({ id: i.id, permissionId: i.permission.id });
      });
      // setItem({ data: detailUserResponse?.addOnItems });
    }
  }, [detailUserResponse?.code]);

  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex(
      (i) => i.name === 'Customer'
    );
    setRoleId(getUserRolesResponse?.data?.content[index]?.id);
  }, [getUserRolesResponse]);

  useEffect(() => {
    setUserPermissions({ data: [] });
    detailUser({ id: params?.id });
    getUserPermissionList();
    getUserRoles();
  }, []);

  useEffect(() => {
    setUserPermissions({ data: [] });
    detailUser({ id: params?.id });
  }, [deleteUserPermissionResponse]);

  useEffect(() => {
    if (uploadImageResponse?.url !== undefined) {
      setData({ ...data, icon: uploadImageResponse?.url });
    }
  }, [uploadImageResponse?.url]);

  useEffect(() => {
    if (uploadImageError?.error !== undefined && uploadImageError?.message) {
      setErrorInfo(uploadImageError?.message);
      handleOpenErrorInfo();
      setKey();
    }
  }, [uploadImageError]);

  useEffect(() => {
    if (getUserPermissionListResponse?.code === 200) {
      const _permissionOptions = [];
      getUserPermissionListResponse?.data?.map((value, index) => {
        _permissionOptions.push(value);
      });
      setPermissionOptions(_permissionOptions);
      console.log('_permissionOptions >> ', _permissionOptions);
    }
  }, [getUserPermissionListResponse?.code]);

  const ItemListComponent = () => {
    const onChange = (e, index) => {
      const { name, value } = e.target;
      const newArray = [...userPermissions.data];
      newArray[index][name] = value;
      setUserPermissions({ data: newArray });
    };

    const onChangeUserPermission = (e, index) => {
      const { value } = e.target;
      console.log('checked', value);
      console.log('user permission', userPermissions);
      if (value !== 'initial') {
        if (checkedValue(value) === -1) {
          const newArray = [...userPermissions.data];
          newArray.push({ permissionId: Number(value) });
          setUserPermissions({ data: newArray });
        } else {
          console.log('here');
          const newArray = [...userPermissions.data];
          newArray.splice(index, 1);
          setUserPermissions({ data: newArray });
        }
      }
    };

    const deleteHandler = async (id, index) => {
      if (id !== undefined) {
        await deleteUserPermission({ id: id });
      }
      const tempItem = userPermissions.data.splice(index, 1);
      setUserPermissions({ data: userPermissions.data });
    };

    const checkedValue = (value) => {
      const index = userPermissions?.data.findIndex(
        (i) => i.permissionId == value
      );
      return index;
    };

    return (
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Hak Akses</Form.Label>
        {permissionOptions?.map((value, index) => {
          // console.log("value checkbox >>> ", value)
          return (
            <Form.Check
              type={'checkbox'}
              id={`permission-${index}`}
              value={value.value}
              label={value.label}
              checked={checkedValue(value.value) === -1 ? false : true}
              onChange={(e) => onChangeUserPermission(e, index)}
            />
          );
        })}
      </Form.Group>
    );
  };
  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>Update Hak Akses</CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.username}
                  type="text"
                  placeholder="Username"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  value={''}
                  disabled
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={data?.name}
                  type="text"
                  placeholder="Nama"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Handphone</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.handphone}
                  type="text"
                  placeholder="Handphone"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  disabled={true}
                  value={data?.email}
                  type="email"
                  placeholder="Email"
                />
              </Form.Group>
            </Col>
          </Row>
          {ItemListComponent()}
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="bg-primary"
          onClick={handleOnSave}
          disabled={updateUserResponse.code === 200 ? true : false}>
          Simpan
        </Button>
      </CardFooter>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  updateUserResponse: state.user.updateUserResponse,
  detailUserResponse: state.user.detailUserResponse,
  deleteUserPermissionResponse: state.user.deleteUserPermissionResponse,
  getUserPermissionListResponse: state.user.getUserPermissionListResponse,
  uploadImageResponse: state.user.uploadImageResponse,
  uploadImageError: state.user.uploadImageError,
  icon: state.product.icon,
  getUserRolesResponse: state.user.getUserRolesResponse,
});
const mapDispatchToProps = {
  updateUser: (payload) => updateUserProps(payload),
  detailUser: (payload) => detailUserProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  deleteUserPermission: (payload) => deleteUserPermissionProps(payload),
  getUserPermissionList: (payload) => getUserPermissionListProps(payload),
  getUserRoles: (payload) => getUserRolesProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomer);
