import React, { useEffect, useState, createRef } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Styles from './styles';
import {
  clearAuth,
  getLogin as getLoginProps,
  doLogout as doLogoutProps,
} from '../authAction';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

function SignIn(props) {
  const {
    getLogin,
    getLoginResponse,
    getLoginError,
    getLoginFetch,
    getLogout,
    getLogoutResponse,
    getLogouError,
    getLogoutFetch,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const ref = createRef();
  const styles = Styles();
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);

  const session = useSelector((state) => state.session);

  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatch(clearAuth());
  };

  let handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  let handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (username && password) {
      const req = {
        username,
        password,
      };
      getLogin(req);
    }
  };
  const { username, password } = inputs;
  if (session.token) {
    setTimeout(() => {
      history.replace('/');
    }, 1500);
  }
  return (
    <Container
      component="main"
      maxWidth="xs"
      className={styles.container}
      ref={ref}>
      <Snackbar open={open} autoHideDuration={4000} onClose={closeToast}>
        <Alert
          onClose={closeToast}
          severity={session.error ? 'error' : 'success'}>
          {session.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <div className={styles.paper}>
        <img src="logo.png" className={styles.logo} />
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="username"
            label="Username / Email Address"
            name="username"
            autoComplete="username"
            size="small"
            onChange={handleChange}
            autoFocus
            error={submitted && !username ? true : false}
            helperText={submitted && !username ? 'Harus diisi' : ''}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={handleChange}
            size="small"
            autoComplete="current-password"
            error={submitted && !password ? true : false}
            helperText={submitted && !password ? 'Harus diisi' : ''}
          />
          <Button
            disabled={getLoginFetch}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submit}>
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Lupa password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="#">
            My Republic
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  getLoginResponse: state.auth.getLoginResponse,
  getLoginError: state.auth.getLoginError,
  getLoginFetch: state.auth.getLoginFetch,
  doLogoutResponse: state.auth.doLogoutResponse,
  doLogoutError: state.auth.doLogoutError,
  doLogoutFetch: state.auth.doLogoutFetch,
});

const mapDispatchToProps = {
  getLogin: (payload) => getLoginProps(payload),
  doLogout: (payload) => doLogoutProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
