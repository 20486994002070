import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';

export const columns = (
  onClickUpdateIcon,
  toogleDeleteConfirmModal,
  handleActive
) => [
  {
    dataField: 'no',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'contractorName',
    text: 'Nama',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.contractorName ?? 'text-center'}>
          {row?.contractorName ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'totalWo',
    text: 'Total WO',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return <div className="text-center">{row?.totalWo ?? '-'}</div>;
    },
  },
  {
    dataField: 'totalWoSameday',
    text: 'Total WO Sameday',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return <div className="text-center">{row?.totalWoSameday ?? '-'}</div>;
    },
  },
  {
    dataField: 'totalWoInProgress',
    text: 'Total WO In Progress',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return <div className="text-center">{row?.totalWoInProgress ?? '-'}</div>;
    },
  },
  {
    dataField: 'totalWoSamedayInProgress',
    text: 'Total WO Sameday In Progress',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className="text-center">
          {row?.totalWoSamedayInProgress ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'totalOnlineTechnician',
    text: 'Total Online Technician',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className="text-center">{row?.totalOnlineTechnician ?? '-'}</div>
      );
    },
  },
  {
    dataField: 'totalTechnicianOnDuty',
    text: 'Total Technician On Duty',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className="text-center">{row?.totalTechnicianOnDuty ?? '-'}</div>
      );
    },
  },
  // {
  //   dataField: '#',
  //   text: 'Area',
  //   headerStyle: { textAlign: 'center' },
  //   style: { verticalAlign: 'middle', textAlign: 'center' },
  //   formatter: (cell, row) => {
  //     return (
  //       row?.areaId.length > 0 ?
  //       row?.areaId.map((i) => (
  //         <div>{i.name}</div>
  //       ))
  //       :
  //       <div>No area</div>
  //     )
  //   }
  // },
  // {
  //   dataField: '#',
  //   text: 'Action',
  //   headerStyle: { width: '150px', textAlign: 'center' },
  //   style: { verticalAlign: 'middle', textAlign: 'center' },
  //   formatter: (cell, row) => {
  //     return (
  //       <div className="container-button">
  //         <div
  //           className="rounded"
  //           onClick={() => onClickUpdateIcon(row)}
  //           >
  //           <CreateIcon/>
  //         </div>
  //         <div
  //           className="rounded"
  //           onClick={() => handleActive(row)}
  //           >
  //           {row?.statusActive ? <VisibilityOffIcon/> : <VisibilityIcon />}
  //         </div>
  //       </div>
  //     );
  //   },
  // },
];
