import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  postLiveChatSettings as postLiveChatSettingsProps,
  resetState as resetStateProps,
} from '@myrepublic-livechat/liveChatAction';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { useRef } from 'react';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

function SettingLiveChat(props) {
  const {
    postLiveChatSettings,
    postLiveChatSettingsResponse,
    pagination,
    PaginationRowHandler,
    PaginationHandler,
    resetState,
    postLiveChatSettingsFetch,
  } = props;
  const [id, setid] = useState(0);
  const history = useHistory();

  const getDataTable = (_pagination = null) => {
    postLiveChatSettings({
      ..._pagination,
      filter: {},
    });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  const handleActive = (row) => {
    // putUserStatus({id: row.id, payload: {active: !row.active}})
  };

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    // resetState({});
    // console.log('handleEdit');
    console.log('click ', row?.id);
    history.push('/update-livechat-setting/' + row?.id);
  };

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
  }, []);

  return (
    <>
      <WrapContainerBased
        key={id}
        withTable
        withPagination
        totalRecord={postLiveChatSettingsResponse?.totalElements || 0}
        totalPage={pagination?.limit}
        wrapTitle={'Live Chat Setting'}
        data={postLiveChatSettingsResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        handleChangePage={handleChangePage}
        showPerPage={1}
        isLoading={postLiveChatSettingsFetch}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        {/* <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        /> */}
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postLiveChatSettingsResponse: state.livechat.postLiveChatSettingsResponse,
  postLiveChatSettingsFetch: state.livechat.postLiveChatSettingsFetch,
});

const mapDispatchToProps = {
  postLiveChatSettings: (payload) => postLiveChatSettingsProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
  resetState: (payload) => resetStateProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingLiveChat);
