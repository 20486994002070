import * as CONST from '@myrepublic-livechat/liveChatConstant';
import * as STATE from '@myrepublic-livechat/liveChatInitialState';

const messageInitialState = {
  ...STATE.messageInitialState,
  action: '',
};

const messagesReducer = (state = messageInitialState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.GET_MESSAGES]: () => ({
      ...state,
      getMessagesFetch: true,
      getMessagesParams: payload,
      action: type,
    }),
    [CONST.GET_MESSAGES_SUCCESS]: () => ({
      ...state,
      getMessagesFetch: false,
      getMessagesResponse: payload,
      action: type,
    }),
    [CONST.GET_MESSAGES_FAILED]: () => ({
      ...state,
      getMessagesFetch: false,
      getMessagesError: payload,
      action: type,
    }),
    [CONST.GET_SELECTED_MESSAGE]: () => ({
      ...state,
      getSelectedMessageFetch: true,
      getSelectedMessageParams: payload,
      action: type,
    }),
    [CONST.GET_SELECTED_MESSAGE_SUCCESS]: () => ({
      ...state,
      getSelectedMessageFetch: false,
      getSelectedMessageResponse: payload,
      action: type,
    }),
    [CONST.GET_SELECTED_MESSAGE_FAILED]: () => ({
      ...state,
      getSelectedMessageFetch: false,
      getSelectedMessageError: payload,
      action: type,
    }),
    [CONST.POST_MESSAGE]: () => ({
      ...state,
      postMessageFetch: true,
      postMessageParams: payload,
      action: type,
    }),
    [CONST.POST_MESSAGE_SUCCESS]: () => ({
      ...state,
      postMessageFetch: false,
      postMessageResponse: payload,
      action: type,
    }),
    [CONST.POST_MESSAGE_FAILED]: () => ({
      ...state,
      postMessageFetch: false,
      postMessageError: payload,
      action: type,
    }),
    [CONST.PUT_READ_MESSAGE]: () => ({
      ...state,
      putReadMessageFetch: true,
      putReadMessageParams: payload,
      action: type,
    }),
    [CONST.PUT_READ_MESSAGE_SUCCESS]: () => ({
      ...state,
      putReadMessageFetch: false,
      putReadMessageResponse: payload,
      action: type,
    }),
    [CONST.PUT_READ_MESSAGE_FAILED]: () => ({
      ...state,
      putReadMessageFetch: false,
      putReadMessageError: payload,
      action: type,
    }),
    [CONST.TAKE_BY_CSAGENT]: () => ({
      ...state,
      takeByCsagentFetch: true,
      takeByCsagentParams: payload,
      action: type,
    }),
    [CONST.TAKE_BY_CSAGENT_SUCCESS]: () => ({
      ...state,
      takeByCsagentFetch: false,
      takeByCsagentResponse: payload,
      action: type,
    }),
    [CONST.TAKE_BY_CSAGENT_FAILED]: () => ({
      ...state,
      takeByCsagentFetch: false,
      takeByCsagentError: payload,
      action: type,
    }),
    [CONST.CLOSE_CHAT_BY_CSAGENT]: () => ({
      ...state,
      closeChatByCsagentFetch: true,
      closeChatByCsagentParams: payload,
      action: type,
    }),
    [CONST.CLOSE_CHAT_BY_CSAGENT_SUCCESS]: () => ({
      ...state,
      closeChatByCsagentFetch: false,
      closeChatByCsagentResponse: payload,
      action: type,
    }),
    [CONST.CLOSE_CHAT_BY_CSAGENT_FAILED]: () => ({
      ...state,
      closeChatByCsagentFetch: false,
      closeChatByCsagentError: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageParams: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_SUCCESS]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageResponse: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_FAILED]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageError: payload,
      action: type,
    }),
    [CONST.SET_IMAGE_URL]: () => ({
      ...state,
      uploadImageFetch: false,
      url: payload,
    }),
    [CONST.POST_LIVE_CHAT_SETTINGS]: () => ({
      ...state,
      postLiveChatSettingsFetch: true,
      postLiveChatSettingsParams: payload,
      action: type,
    }),
    [CONST.POST_LIVE_CHAT_SETTINGS_FAILED]: () => ({
      ...state,
      postLiveChatSettingsFetch: false,
      postLiveChatSettingsError: payload,
      action: type,
    }),
    [CONST.POST_LIVE_CHAT_SETTINGS_SUCCESS]: () => ({
      ...state,
      postLiveChatSettingsFetch: false,
      postLiveChatSettingsResponse: payload,
      action: type,
    }),
    [CONST.GET_DETAIL_LIVE_CHAT_SETTING]: () => ({
      ...state,
      getDetailLiveChatSettingFetch: false,
      getDetailLiveChatSettingParams: payload,
      action: type,
    }),
    [CONST.GET_DETAIL_LIVE_CHAT_SETTING_FAILED]: () => ({
      ...state,
      getDetailLiveChatSettingFetch: false,
      getDetailLiveChatSettingError: payload,
      action: type,
    }),
    [CONST.GET_DETAIL_LIVE_CHAT_SETTING_SUCCESS]: () => ({
      ...state,
      getDetailLiveChatSettingFetch: false,
      getDetailLiveChatSettingResponse: payload,
      action: type,
    }),
    [CONST.PUT_DETAIL_LIVE_CHAT_SETTING]: () => ({
      ...state,
      putDetailLiveChatSettingFetch: false,
      putDetailLiveChatSettingParams: payload,
      action: type,
    }),
    [CONST.PUT_DETAIL_LIVE_CHAT_SETTING_FAILED]: () => ({
      ...state,
      putDetailLiveChatSettingFetch: false,
      putDetailLiveChatSettingError: payload,
      action: type,
    }),
    [CONST.PUT_DETAIL_LIVE_CHAT_SETTING_SUCCESS]: () => ({
      ...state,
      putDetailLiveChatSettingFetch: false,
      putDetailLiveChatSettingResponse: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_STATISTIC]: () => ({
      ...state,
      postCSAgentChatStatisticsFetch: false,
      postCSAgentChatStatisticsParams: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_STATISTIC_FAILED]: () => ({
      ...state,
      postCSAgentChatStatisticsFetch: false,
      postCSAgentChatStatisticsError: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_STATISTIC_SUCCESS]: () => ({
      ...state,
      postCSAgentChatStatisticsFetch: false,
      postCSAgentChatStatisticsResponse: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_STATISTIC_BY_ID]: () => ({
      ...state,
      postCSAgentChatStatisticsByIdFetch: false,
      postCSAgentChatStatisticsByIdParams: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_STATISTIC_BY_ID_FAILED]: () => ({
      ...state,
      postCSAgentChatStatisticsByIdFetch: false,
      postCSAgentChatStatisticsByIdError: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_STATISTIC_BY_ID_SUCCESS]: () => ({
      ...state,
      postCSAgentChatStatisticsByIdFetch: false,
      postCSAgentChatStatisticsByIdResponse: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_SUMMARY]: () => ({
      ...state,
      postCSAgentChatSummaryFetch: true,
      postCSAgentChatSummaryParams: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_SUMMARY_FAILED]: () => ({
      ...state,
      postCSAgentChatSummaryFetch: false,
      postCSAgentChatSummaryError: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_SUMMARY_SUCCESS]: () => ({
      ...state,
      postCSAgentChatSummaryFetch: false,
      postCSAgentChatSummaryResponse: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_MONITORING]: () => ({
      ...state,
      postCSAgentChatMonitoringFetch: true,
      postCSAgentChatMonitoringParams: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_MONITORING_FAILED]: () => ({
      ...state,
      postCSAgentChatMonitoringFetch: false,
      postCSAgentChatMonitoringError: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_MONITORING_SUCCESS]: () => ({
      ...state,
      postCSAgentChatMonitoringFetch: false,
      postCSAgentChatMonitoringResponse: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_FORCE_LOGOUT]: () => ({
      ...state,
      postCSAgentForceLogoutFetch: false,
      postCSAgentForceLogoutParams: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_FORCE_LOGOUT_FAILED]: () => ({
      ...state,
      postCSAgentForceLogoutFetch: false,
      postCSAgentForceLogoutError: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_FORCE_LOGOUT_SUCCESS]: () => ({
      ...state,
      postCSAgentForceLogoutFetch: false,
      postCSAgentForceLogoutResponse: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_HISTORY]: () => ({
      ...state,
      postCSAgentHistoryFetch: true,
      postCSAgentHistoryParams: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_HISTORY_FAILED]: () => ({
      ...state,
      postCSAgentHistoryFetch: false,
      postCSAgentHistoryError: payload,
      action: type,
    }),
    [CONST.POST_CS_AGENT_HISTORY_SUCCESS]: () => ({
      ...state,
      postCSAgentHistoryFetch: false,
      postCSAgentHistoryResponse: payload,
      action: type,
    }),
    [CONST.RESET_STATE]: () => ({
      ...messageInitialState,
      action: type,
    }),
    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default messagesReducer;
