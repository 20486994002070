import React from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import './style.scss';

const FilterButton = (props) => {
  const { children } = props;
  return (
    <UncontrolledDropdown direction="left" inNavbar>
      <DropdownToggle
        // outline
        // color="primary"
        className="d-flex align-items-center btn-outline-custom justify-content-center w-100 px-2 rounded pill">
        <div>Filter</div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-4xl custom-dropdown-menu-left">
        {children}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default FilterButton;
