function truncate(str, n) {
  let newString = str.replace(/(<([^>]+)>)/gi, '');
  return newString.length > n ? newString.substr(0, n - 1) + '...' : newString;
}

const isAllowed = (session, access = '') => {
  const userPermissions = [];

  if (!session) return false;
  let result = false;
  if (session?.role?.name === 'Superadmin') result = true;
  else if (session?.userPermissions) {
    session?.userPermissions?.forEach((value) => {
      const accessNode = value.permission.path + '_' + value.permission.access;
      userPermissions.push(accessNode);
    });
    access.split(',').forEach((e) => {
      if (userPermissions.includes(e.trim())) result = true;
      else if (access === '') result = true;
    });
  }
  return result;
};

const pushToArray = (arr, obj) => {
  const index = arr.findIndex(
    (e) => parseInt(e.sender.id) === parseInt(obj.senderId)
  );

  if (index === -1) {
    arr.push(obj);
  } else {
    arr[index] = obj;
  }
  return arr;
};

const defaultPaging = {
  limit: 10,
  offset: 0,
  load: false,
};

const defaultPaginationRowHandler = {
  value: 10,
  label: 10,
};

export {
  truncate,
  isAllowed,
  pushToArray,
  defaultPaging,
  defaultPaginationRowHandler,
};
