export const POST_USERS = 'POST_USERS';
export const POST_USERS_SUCCESS = 'POST_USERS_SUCCESS';
export const POST_USERS_FAILED = 'POST_USERS_FAILED';
export const POST_USERS_RESET = 'POST_USERS_RESET';
export const TEKNISI_VENDOR = 'TEKNISI_VENDOR';
export const TEKNISI_VENDOR_SUCCESS = 'TEKNISI_VENDOR_SUCCESS';
export const TEKNISI_VENDOR_FAILED = 'TEKNISI_VENDOR_FAILED';
export const TEKNISI_VENDOR_RESET = 'TEKNISI_VENDOR_RESET';
export const PUT_USER_STATUS = 'PUT_USER_STATUS';
export const PUT_USER_STATUS_SUCCESS = 'PUT_USER_STATUS_SUCCESS';
export const PUT_USER_STATUS_FAILED = 'PUT_USER_STATUS_FAILED';
export const RESET_STATE = 'RESET_STATE';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const DELETE_USER_PERMISSION = 'DELETE_USER_PERMISSION';
export const DELETE_USER_PERMISSION_SUCCESS = 'DELETE_USER_PERMISSION_SUCCESS';
export const DELETE_USER_PERMISSION_FAILED = 'DELETE_USER_PERMISSION_FAILED';
export const GET_USER_PERMISSION_LIST = 'GET_USER_PERMISSION_LIST';
export const GET_USER_PERMISSION_LIST_SUCCESS =
  'GET_USER_PERMISSION_LIST_SUCCESS';
export const GET_USER_PERMISSION_LIST_FAILED =
  'GET_USER_PERMISSION_LIST_FAILED';
export const DETAIL_USER = 'DETAIL_USER';
export const DETAIL_USER_SUCCESS = 'DETAIL_USER_SUCCESS';
export const DETAIL_USER_FAILED = 'DETAIL_USER_FAILED';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';

export const SET_IMAGE_URL = 'SET_IMAGE_URL';

export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILED = 'GET_USER_ROLES_FAILED';

export const GET_TECHNICIAN_RATING = 'GET_TECHNICIAN_RATING_ROLES';
export const GET_TECHNICIAN_RATING_SUCCESS = 'GET_TECHNICIAN_RATING_SUCCESS';
export const GET_TECHNICIAN_RATING_FAILED = 'GET_TECHNICIAN_RATING_FAILED';

// Get vendor list
export const GET_LIST_VENDOR = 'GET_LIST_VENDOR';
export const GET_LIST_VENDOR_SUCCESS = 'GET_LIST_VENDOR_SUCCESS';
export const GET_LIST_VENDOR_FAILED = 'GET_LIST_VENDOR_FAILED';