import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { PaginationHandler as PaginationHandlerProps } from '@myrepublic-bootstrap/bootstrapAction';
import './styles.scss';
import { useState } from 'react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

function CustomPagination(props) {
  const {
    PaginationHandler,
    pagination,
    // paginationRow,
    // optionsSelect,
    // optionsValue,
    handleChangePage,
    totalRecords,
    pageLimit,
    showPerPage,
  } = props;

  const [paginationData, setPaginationData] = useState({
    pageLimit,
    currentPage: 1,
    selectedOption: 10,
    valueSelected: 10,
  });

  const pageNeighbours =
    typeof showPerPage === 'number' ? Math.max(0, Math.min(showPerPage, 2)) : 0;

  const totalPages = Math.ceil(totalRecords / pageLimit);

  const gotoPage = (page) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));
    // const newValue = {
    //   currentPage,
    //   totalPages,
    //   // pageLimit,
    //   totalRecords,
    // };
    setPaginationData((val) => ({ ...val, currentPage }));

    PaginationHandler({
      ...pagination,
      offset: Number(page - 1),
    });

    handleChangePage(parsePagination({ ...pagination, page: Number(page) }));
  };

  const parsePagination = (_pagination) => {
    return {
      ...pagination,
      limit: Number(_pagination.limit),
      offset: Number(_pagination.page) - 1,
    };
  };

  const handleMoveLeft = (evt) => {
    evt.preventDefault();
    gotoPage(paginationData?.currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = (evt) => {
    evt.preventDefault();
    gotoPage(paginationData?.currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = () => {
    const currentPage = paginationData?.currentPage;

    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = totalPages - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };

  const handleChange = (selectedOption) => {
    const currentPage = Math.max(0, Math.min(1, totalPages));
    setPaginationData((val) => ({
      ...val,
      selectedOption: selectedOption?.value,
      pageLimit: selectedOption?.value,
      currentPage,
    }));

    PaginationHandler({
      ...pagination,
      limit: Number(selectedOption?.value),
      offset: 0,
    });

    handleChangePage(
      parsePagination({ limit: selectedOption?.value, page: 1 })
    );
  };

  const handleClick = (page, evt) => {
    evt.preventDefault();
    gotoPage(page);
    PaginationHandler({
      ...pagination,
      offset: Number(page - 1),
    });
  };

  const pages = fetchPageNumbers();
  const rangeData = [
    { value: 10, label: 10 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    { value: 200, label: 200 },
  ];

  return (
    <Row className="pagination-container">
      <Col className="d-flex flex-row align-items-center mb-2">
        <Select
          value={paginationData?.selectedOption}
          options={rangeData}
          onChange={(e) => {
            handleChange(e);
          }}
          className="select mx-2"
          placeholder={paginationData?.selectedOption}
        />
        <label>
          Menampilkan{' '}
          {paginationData?.selectedOption?.value
            ? paginationData.selectedOption.value
            : paginationData?.selectedOption}{' '}
          Data dari Total{' '}
          {totalRecords < paginationData?.selectedOption?.value
            ? `-${totalRecords}`
            : totalRecords}
        </label>
      </Col>
      <Col>
        <ul className="pagination d-flex mt-1 justify-content-end">
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className="page-item d-flex flex-row">
                  <a
                    className="page-link"
                    style={{ fontWeight: 'bold' }}
                    href="#"
                    aria-label="Previous"
                    onClick={handleMoveLeft}>
                    <span aria-hidden="true"> {'<<'} </span>
                  </a>
                  {/* <a
                    className="page-link"
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    aria-label="Previous"
                    onClick={handleMoveLeft}>
                    <span aria-hidden="true"> {'<'} </span>
                  </a> */}
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className="page-item d-flex flex-row">
                  {/* <a
                    className="page-link"
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    aria-label="Next"
                    onClick={handleMoveRight}>
                    <span aria-hidden="true"> {'>'} </span>
                  </a> */}
                  <a
                    className="page-link"
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    aria-label="Next"
                    onClick={handleMoveRight}>
                    <span aria-hidden="true"> {'>>'} </span>
                  </a>
                </li>
              );

            return (
              <li
                key={index}
                className={`page-item${
                  paginationData?.currentPage === page ? ' active' : ''
                }`}>
                <a
                  className="page-link"
                  href="#"
                  style={{ fontWeight: 'bold' }}
                  onClick={(e) => {
                    handleClick(page, e);
                  }}>
                  {page}
                </a>
              </li>
            );
          })}
        </ul>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  paginationRow: state.paging.paginationRow,
});

const mapDispatchToProps = {
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPagination);
