import { combineReducers } from 'redux';
import * as STATE from '@myrepublic-bootstrap/bootstrapInitialState';
import * as CONST from '@myrepublic-bootstrap/bootstrapContant';
import { loginReducer, sessionReducer } from '@myrepublic-auth/authReducers';
import awardsReducer from '@myrepublic-awards/awardReducers';
import troubleReducer from '@myrepublic-troubleshooting/troubleShootingReducer';
import forgotPasswordReducer from '@myrepublic-forgotPassword/forgotPasswordReducer';
import resetPasswordReducer from '@myrepublic-resetPassword/resetPasswordReducer';
import productsReducer from '@myrepublic-products/productReducers';
import liveChatReducer from '@myrepublic-livechat/liveChatReducer';
import usersReducer from '@myrepublic-users/usersReducer';
import wokumoReducer from '@myrepublic-wokumo/wokumoReducer';

const initialState = {
  ...STATE.paginationLimit,
  action: '',
};

const tableInitialState = {
  ...STATE.dataTableInitialState,
  action: '',
};

const PaginationReducer = (state = initialState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.SET_PAGINATION]: () => ({
      ...state,
      pagination: payload,
      action: type,
    }),
    [CONST.SET_PAGINATION_ROW]: () => ({
      ...state,
      paginationRow: payload,
      action: type,
    }),
    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export const tableReducer = (state = tableInitialState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.GET_DATA_TABLE]: () => ({
      ...state,
      getDataTableFetch: true,
      getDataTableParams: payload,
      action: type,
    }),
    [CONST.GET_DATA_TABLE_SUCCESS]: () => ({
      ...state,
      getDataTableFetch: false,
      getDataTableResponse: payload,
      action: type,
    }),
    [CONST.GET_DATA_TABLE_FAILED]: () => ({
      ...state,
      getDataTableFetch: false,
      getDataTableResponse: payload,
      action: type,
    }),
    [CONST.SET_FIREBASE]: () => ({
      ...state,
      firebaseData: payload,
      action: type,
    }),
    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

const bootstrapReducer = combineReducers({
  auth: loginReducer,
  paging: PaginationReducer,
  session: sessionReducer,
  award: awardsReducer,
  trouble: troubleReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  bootstrap: tableReducer,
  product: productsReducer,
  livechat: liveChatReducer,
  user: usersReducer,
  wokumo: wokumoReducer
});

export default bootstrapReducer;
