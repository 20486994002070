import { SelectComponent } from '@myrepublic-components/index';
import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Col, Row, Label as LSLabel, Input, Button } from 'reactstrap';
import './FilterMenu.scss';

function FilterMenu(props) {
  const {
    filter,
    setFilter,
    listVendorOptions,
    handleGenerateList,
    isLoading,
  } = props;

  const handleChange = (key, value) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };

  const handleVendorChange = (event) => {
    handleChange('vendorId', event ? event.vendorId : null);
  };

  const handleNameChange = (event) => {
    handleChange('name', event ? event.target.value : null);
  };

  const handleHandphoneChange = (event) => {
    handleChange('handphone', event ? event.target.value : null);
  };

  const handleEmailChange = (event) => {
    handleChange('email', event ? event.target.value : null);
  };

  const handleSubmit = () => {
    handleGenerateList(filter);
  };

  const handleResetFilter = () => {
    setFilter({
      name: '',
      email: '',
      handphone: '',
    });
  };

  return (
    <>
      <Row>
        <Col xs="12" md="6" className="mt-3">
          <FormGroup>
            <Label>Vendor : </Label>
            <SelectComponent
              name="vendorOptions"
              placeholder="Pilih vendor.."
              options={listVendorOptions}
              value={
                filter?.vendorId
                  ? listVendorOptions.find(
                      (item) => item.vendorId === filter.vendorId
                    )
                  : null
              }
              onChange={handleVendorChange}
              isClearable
            />
          </FormGroup>
        </Col>

        <Col xs="12" md="6" className="mt-3">
          <FormGroup>
            <Label>Name : </Label>
            <Input
              placeholder="Masukkan nama ..."
              value={filter?.name}
              onChange={handleNameChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs="12" md="6" className="mt-3">
          <FormGroup>
            <Label>Handphone : </Label>
            <Input
              placeholder="Masukkan username ..."
              type="number"
              value={filter?.handphone}
              onChange={handleHandphoneChange}
            />
          </FormGroup>
        </Col>

        <Col xs="12" md="6" className="mt-3">
          <FormGroup>
            <Label>Email : </Label>
            <Input
              placeholder="Masukkan email ..."
              value={filter?.email}
              onChange={handleEmailChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col className="d-flex align-items-center justify-content-end">
          <div className="text-right">
            <Button
              size="sm"
              className="btn-filter mx-3"
              onClick={handleResetFilter}>
              Set Ulang
            </Button>
            <Button
              size="sm"
              color="primary"
              disabled={isLoading}
              onClick={handleSubmit}>
              Terapkan
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

const Label = ({ children, required }) => {
  return (
    <LSLabel className="text-primary-custom mb-1">
      {children}
      {required ? <span className="text-danger">*</span> : null}
    </LSLabel>
  );
};

export default FilterMenu;
