import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';

export const columns = (
  onClickUpdateIcon,
  toogleDeleteConfirmModal,
  handleActive
) => [
  {
    dataField: 'no',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'woNumber',
    text: 'Work Order Number',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.woNumber ?? 'text-center'}>
          {row?.woNumber ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: '#',
    text: 'Customer Id',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.customer?.id ?? 'text-center'}>
          {row?.customer?.id ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'ticketCategory',
    text: 'Ticket Category',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.ticketCategory ?? 'text-center'}>
          {row?.ticketCategory ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'ticketNumber',
    text: 'Ticket Number',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.ticketNumber ?? 'text-center'}>
          {row?.ticketNumber ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'woStatus',
    text: 'Work Order Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.woStatus ?? 'text-center'}>
          {row?.woStatus ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'woServiceStatus',
    text: 'WO Service Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.woServiceStatus ?? 'text-center'}>
          {row?.woServiceStatus ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'woServiceType',
    text: 'WO Service Type',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.woServiceType ?? 'text-center'}>
          {row?.woServiceType ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'woTechnicianStatus',
    text: 'WO Technician Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.woTechnicianStatus ?? 'text-center'}>
          {row?.woTechnicianStatus ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'description',
    text: 'Description',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.description ?? 'text-center'}>
          {row?.description ?? '-'}
        </div>
      );
    },
  },
];
