import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';

export const columns = (
  onClickUpdateIcon,
  toogleDeleteConfirmModal,
  handleActive
) => [
  {
    dataField: 'no',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'name',
    text: 'Nama',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.name ?? 'text-center'}>{row?.name ?? '-'}</div>
      );
    },
  },
  {
    dataField: 'username',
    text: 'Username',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.username ?? 'text-center'}>
          {row?.username ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'handphone',
    text: 'Handphone',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.handphone ?? 'text-center'}>
          {row?.handphone ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'email',
    text: 'Email',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.email ?? 'text-center'}>{row?.email ?? '-'}</div>
      );
    },
  },
  {
    dataField: '#',
    text: 'Action',
    headerStyle: { width: '150px', textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <div className="container-button">
          <div className="rounded" onClick={() => onClickUpdateIcon(row)}>
            <CreateIcon />
          </div>
          {/* <div
            className="rounded"
            onClick={() => toogleDeleteConfirmModal(row)}>
            <DeleteOutlineIcon />
          </div> */}
          <div className="rounded" onClick={() => handleActive(row)}>
            {row?.active ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </div>
        </div>
      );
    },
  },
];
