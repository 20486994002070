import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  postCSAgentChatStatistics as postCSAgentChatStatisticsProps,
  postCSAgentChatSummary as postCSAgentChatSummaryProps,
  postCSAgentChatStatisticsById as postCSAgentChatStatisticsByIdProps,
  resetState as resetStateProps,
} from '@myrepublic-livechat/liveChatAction';

import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';

import { columns } from './column';
import { useHistory } from 'react-router';
import { TextField } from '@material-ui/core';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Statistik(props) {
  const {
    postCSAgentChatStatistics,
    postCSAgentChatStatisticsResponse,
    postCSAgentChatSummary,
    postCSAgentChatSummaryResponse,
    postCSAgentChatStatisticsById,
    postCSAgentChatStatisticsByIdResponse,
    pagination,
    match: { params },
    resetState,
    PaginationHandler,
    PaginationRowHandler,
    postCSAgentChatSummaryFetch,
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [totalData, setTotalData] = useState();
  const [roleId, setRoleId] = useState();
  const [date, setDate] = useState({
    createdAtStart: null,
    createdAtEnd: null,
  });
  const [filter, setFilter] = useState();
  const history = useHistory();

  const [deleteParam, setDeleteParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });

  // const getDataTable = () => {
  //   if (params == undefined) postCSAgentChatStatistics({...pagination});
  //   else {
  //     postCSAgentChatStatisticsById({...pagination, filter: {csagentId: Number(params.id)}});
  //   }
  // };
  // kalau ada perbaikan di waktu yang akan datang, mending bikin fungsi fetch by id nya dipisah aja

  const getDataTable = (_pagination = null) => {
    postCSAgentChatStatistics({ ..._pagination });
  };

  const checkFilter = () => {};

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleActive = (row) => {
    // putUserStatus({id: row.id, payload: {active: !row.active}})
  };

  const handleDelete = (row) => {
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    // resetState({});
    console.log('handleEdit');
    history.push('/update-customer/' + row?.id);
  };

  const modalShowData = () => {
    // setShowCreateModal(true);
    // history.push('/create-product');
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  useEffect(() => {
    console.log(
      'postCSAgentChatStatisticsById',
      postCSAgentChatStatisticsByIdResponse,
      'and pagination is',
      pagination
    );
  }, [postCSAgentChatStatisticsById]);

  useEffect(() => {
    // getDataTable();
    postCSAgentChatSummary();
  }, [date, pagination]);

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
  }, []);

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={
          params?.id != undefined
            ? postCSAgentChatStatisticsByIdResponse?.totalElements
            : postCSAgentChatStatisticsResponse?.totalElements
        }
        totalPage={pagination.limit}
        wrapTitle={'Statistik'}
        data={
          params?.id != undefined
            ? postCSAgentChatStatisticsByIdResponse?.content
            : postCSAgentChatStatisticsResponse?.content
        }
        columns={columns(handleEdit, handleDelete, handleActive)}
        handleChangePage={handleChangePage}
        showPerPage={1}
        isLoading={postCSAgentChatSummaryFetch}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        {params?.id == undefined && (
          <div style={{ marginBottom: 20 }}>
            <h6>
              <b>Summary</b>
            </h6>
            <div>
              <b>Name: </b>
              {postCSAgentChatSummaryResponse?.name}
            </div>
            <div>
              <b>Username: </b>
              {postCSAgentChatSummaryResponse?.username}
            </div>
            <div>
              <b>CS Agent ID: </b>
              {postCSAgentChatSummaryResponse?.csagentId}
            </div>
            <div>
              <b>Email: </b>
              {postCSAgentChatSummaryResponse?.email}
            </div>
            <div>
              <b>Handphone: </b>
              {postCSAgentChatSummaryResponse?.handphone}
            </div>
            <div>
              <b>Total customer Served: </b>
              {postCSAgentChatSummaryResponse?.totalCustomersServed}
            </div>
          </div>
        )}

        <h6>
          <b>Statistic Table</b>
        </h6>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 10,
              alignItems: 'center',
              marginBottom: 20,
            }}>
            <div style={{ marginLeft: 5, marginRight: 5 }}>
              <b>Filter From</b>
            </div>
            <TextField
              type="date"
              onChange={(e) => setDate(e.target.value)}
              style={{ marginLeft: 5 }}
              size="small"
            />
            <div style={{ marginLeft: 5, marginRight: 5 }}>
              <b>To</b>
            </div>
            <TextField
              type="date"
              onChange={(e) => setDate(e.target.value)}
              style={{ marginLeft: 5 }}
              size="small"
            />
          </div>
          <div style={{ marginTop: 10, marginBottom: 20, height: 5 }}>
            <Button size="small" sx={{ height: 5 }}>
              Filter
            </Button>
          </div>
          {/* <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button size="sm" onClick={() => history.push('/create-work-order')}>
            {' '}
            Filter
          </Button>
        </div> */}
        </div>
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postCSAgentChatStatisticsResponse:
    state.livechat.postCSAgentChatStatisticsResponse,
  postCSAgentChatSummaryResponse: state.livechat.postCSAgentChatSummaryResponse,
  postCSAgentChatStatisticsByIdResponse:
    state.livechat.postCSAgentChatStatisticsByIdResponse,
  postCSAgentChatSummaryFetch: state.livechat.postCSAgentChatSummaryFetch,
});

const mapDispatchToProps = {
  postCSAgentChatStatistics: (payload) =>
    postCSAgentChatStatisticsProps(payload),
  postCSAgentChatSummary: (payload) => postCSAgentChatSummaryProps(payload),
  postCSAgentChatStatisticsById: (payload) =>
    postCSAgentChatStatisticsByIdProps(payload),
  resetState: (payload) => resetStateProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(Statistik);
