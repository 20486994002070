import * as CONST from '@myrepublic-wokumo/wokumoConstant';

export const getVendor = (payload) => ({
  type: CONST.GET_VENDOR,
  payload,
});

export const getVendorSuccess = (payload) => ({
  type: CONST.GET_VENDOR_SUCCESS,
  payload,
});

export const getVendorFailed = (payload) => ({
  type: CONST.GET_VENDOR_FAILED,
  payload,
});

export const getWorkOrder = (payload) => ({
  type: CONST.GET_WORK_ORDER,
  payload,
});

export const getWorkOrderSuccess = (payload) => ({
  type: CONST.GET_WORK_ORDER_SUCCESS,
  payload,
});

export const getWorkOrderFailed = (payload) => ({
  type: CONST.GET_WORK_ORDER_FAILED,
  payload,
});

export const postWorkOrder = (payload) => ({
  type: CONST.POST_WORK_ORDER,
  payload,
});

export const postWorkOrderSuccess = (payload) => ({
  type: CONST.POST_WORK_ORDER_SUCCESS,
  payload,
});

export const postWorkOrderFailed = (payload) => ({
  type: CONST.POST_WORK_ORDER_FAILED,
  payload,
});

export const putVendorStatus = (payload) => ({
  type: CONST.PUT_VENDOR_STATUS,
  payload,
});

export const putVendorStatusSuccess = (payload) => ({
  type: CONST.PUT_VENDOR_STATUS_SUCCESS,
  payload,
});

export const putVendorStatusFailed = (payload) => ({
  type: CONST.PUT_VENDOR_STATUS_FAILED,
  payload,
});

export const postVendor = (payload) => ({
  type: CONST.POST_VENDOR,
  payload,
});

export const postVendorSuccess = (payload) => ({
  type: CONST.POST_VENDOR_SUCCESS,
  payload,
});

export const postVendorFailed = (payload) => ({
  type: CONST.POST_VENDOR_FAILED,
  payload,
});

export const putVendor = (payload) => ({
  type: CONST.PUT_VENDOR,
  payload,
});

export const putVendorSuccess = (payload) => ({
  type: CONST.PUT_VENDOR_SUCCESS,
  payload,
});

export const putVendorFailed = (payload) => ({
  type: CONST.PUT_VENDOR_FAILED,
  payload,
});

export const getVendorDetail = (payload) => ({
  type: CONST.GET_VENDOR_DETAIL,
  payload,
});

export const getVendorDetailSuccess = (payload) => ({
  type: CONST.GET_VENDOR_DETAIL_SUCCESS,
  payload,
});

export const getVendorDetailFailed = (payload) => ({
  type: CONST.GET_VENDOR_DETAIL_FAILED,
  payload,
});

export const postVendorStatistics = (payload) => ({
  type: CONST.POST_VENDOR_STATISTIC,
  payload,
});

export const postVendorStatisticsSuccess = (payload) => ({
  type: CONST.POST_VENDOR_STATISTIC_SUCCESS,
  payload,
});

export const postVendorStatisticsFailed = (payload) => ({
  type: CONST.POST_VENDOR_STATISTIC_FAILED,
  payload,
});
export const resetState = (payload) => ({
  type: CONST.RESET_STATE,
  payload,
});
