import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import {
  getProduct as getProductProps,
  postProduct as postProductProps,
  deleteProduct as deleteProductProps,
  putProduct as putProductProps,
  uploadImage as uploadImageProps,
  resetState as resetStateProps,
} from '@myrepublic-products/productAction';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from './../../../bootstrap/bootstrapAction';
import { defaultPaging, defaultPaginationRowHandler } from 'src/utils';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Products(props) {
  const {
    getProduct,
    getProductsResponse,
    deleteProductResponse,
    deleteProductError,
    deleteProduct,
    postProductResponse,
    postProductError,
    postProduct,
    putProductResponse,
    putProductError,
    putProduct,
    pagination,
    uploadImage,
    uploadImageResponse,
    uploadImageFetch,
    uploadImageError,
    icon,
    resetState,
    getProductFetch,
    PaginationHandler,
    PaginationRowHandler,
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [key, setKey] = useState(0);
  const history = useHistory();
  let closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErrorInfo(false);
  };

  const [deleteParam, setDeleteParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });
  const [editParam, setEditParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });
  const [data, setData] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });
  const getDataTable = (_pagination = null) => {
    getProduct({ ..._pagination });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  const handleOpenErrorInfo = () => {
    setOpenErrorInfo(true);
  };
  const handleClose = () => {
    setShowCreateModal(false);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    deleteProduct({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };
  const handleDelete = (row) => {
    setDeleteParam({
      icon: row?.icon,
      code: row?.code,
      name: row?.name,
      description: row?.description,
      data: row,
      addOnItemListName: row?.addOnItems[0]?.name,
      addOnItemListPrice: row?.addOnItems[0]?.price,
      tv: false,
    });
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    resetState({});
    setEditParam({
      icon: row?.icon,
      name: row?.name,
      code: row?.code,
      description: row?.description,
      data: row,
      addOnItemListName: row?.addOnItems[0]?.name,
      addOnItemListPrice: row?.addOnItems[0]?.price,
      tv: false,
    });
    history.push('/update-product/' + row?.id);
  };

  const modalShowData = () => {
    // setShowCreateModal(true);
    resetState({});
    history.push('/create-product');
  };

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={modalShowData}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };

  useEffect(() => {
    // getDataTable();
    setDeleteParam({});
  }, [deleteProductResponse, pagination]);

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
  }, []);

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={getProductsResponse?.totalElements}
        totalPage={pagination.limit}
        wrapTitle={'Add On'}
        data={getProductsResponse?.content}
        columns={columns(handleEdit, handleDelete)}
        withButton={buttonComponent()}
        isLoading={getProductFetch}
        handleChangePage={handleChangePage}
        showPerPage={1}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  getProductsResponse: state.product.getProductsResponse,
  deleteProductResponse: state.product.deleteProductResponse,
  postProductResponse: state.product.postProductResponse,
  putProductResponse: state.product.putProductResponse,
  uploadImageResponse: state.product.uploadImageResponse,
  uploadImageError: state.product.uploadImageError,
  getProductFetch: state.product.getProductFetch,
  icon: state.product.icon,
  pagination: state.paging.pagination,
});

const mapDispatchToProps = {
  getProduct: (payload) => getProductProps(payload),
  postProduct: (payload) => postProductProps(payload),
  deleteProduct: (payload) => deleteProductProps(payload),
  putProduct: (payload) => putProductProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  resetState: () => resetStateProps(),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);
