import React from 'react';

import { Lock, LockOpen } from '@material-ui/icons';

export const columns = (handleForceLogout) => [
  {
    dataField: 'no',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'name',
    text: 'Name',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'status',
    text: 'Status',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'maxQueueChat',
    text: 'Load Balance',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'countChatActive',
    text: 'Current Load',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'averageHourTime',
    text: 'Summary (AHT)',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'totalCustomersServed',
    text: 'Total Customer Served',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: '#',
    text: 'Action',
    headerStyle: { width: '70px', textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <div className="rounded" onClick={() => handleForceLogout(row)}>
          {row.status === 'OFFLINE' ? <LockOpen /> : <Lock />}
        </div>
      );
    },
  },
];
