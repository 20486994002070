import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'reactstrap';
import {
  teknisiVendor as teknisiVendorProps,
  putUserStatus as putUserStatusProps,
  resetState as resetStateProps,
} from '@myrepublic-users/usersAction';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function TeknisiVendor(props) {
  const {
    pagination,
    teknisiVendor,
    teknisiVendorResponse,
    putUserStatus,
    putUserStatusResponse,
    PaginationRowHandler,
    PaginationHandler,
    resetState,
    teknisiVendorFetch,
  } = props;
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [id, setid] = useState(0);
  const history = useHistory();

  const getDataTable = (_pagination = null) => {
    teknisiVendor({
      data: { ..._pagination },
    });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  // const handleOpenErrorInfo = () => {
  //   setOpenErrorInfo(true);
  // };

  // const handleClose = () => {
  //   setShowCreateModal(false);
  // };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    // deleteProduct({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };

  const handleDelete = (row) => {
    setOpenModalDelete(true);
  };

  const handleEdit = (row) => {
    console.log(row);
    history.push(`/detail-teknisi-vendor/${row.id}`);
  };

  const handleApprove = (row) => {
    putUserStatus({ id: row.id, payload: { active: !row.active } });
  };

  const handleReview = (row) => {
    console.log('row is', row);
  };

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WrapContainerBased
        key={id}
        withTable
        withPagination
        totalRecord={teknisiVendorResponse?.data?.totalElements || 0}
        totalPage={pagination.limit}
        data={teknisiVendorResponse?.data?.content}
        wrapTitle={'Teknisi'}
        columns={columns(handleEdit, handleDelete, handleApprove, handleReview)}
        isLoading={teknisiVendorFetch}
        handleChangePage={handleChangePage}
        showPerPage={1}>
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  teknisiVendorResponse: state.user.teknisiVendorResponse,
  putUserStatusResponse: state.user.putUserStatusResponse,
  getUserRolesResponse: state.user.getUserRolesResponse,
  teknisiVendorFetch: state.user.teknisiVendorFetch,
});

const mapDispatchToProps = {
  teknisiVendor: (payload) => teknisiVendorProps(payload),
  putUserStatus: (payload) => putUserStatusProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
  resetState: (payload) => resetStateProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(TeknisiVendor);
