import * as CONST from '@myrepublic-products/productConstant';
import * as STATE from '@myrepublic-products/productInitialState';

const productsInitialState = {
  ...STATE.productInitialState,
  action: '',
};

const productsReducer = (state = productsInitialState, action) => {
  const { payload, type } = action;
  const actions = {
    [CONST.GET_PRODUCTS]: () => ({
      ...state,
      getProductFetch: true,
      getProductsParams: payload,
      action: type,
    }),
    [CONST.GET_PRODUCTS_SUCCESS]: () => ({
      ...state,
      getProductFetch: false,
      getProductsResponse: payload,
      action: type,
    }),
    [CONST.GET_PRODUCTS_FAILED]: () => ({
      ...state,
      getProductFetch: false,
      postProductError: payload,
      action: type,
    }),
    [CONST.GET_PRODUCTS_TV]: () => ({
      ...state,
      getProductTvFetch: true,
      getProductsTvParams: payload,
      action: type,
    }),
    [CONST.GET_PRODUCTS_TV_SUCCESS]: () => ({
      ...state,
      getProductTvFetch: false,
      getProductsTvResponse: payload,
      action: type,
    }),
    [CONST.GET_PRODUCTS_TV_FAILED]: () => ({
      ...state,
      getProductTvFetch: false,
      postProductTvError: payload,
      action: type,
    }),
    [CONST.POST_PRODUCTS]: () => ({
      ...state,
      postProductFetch: true,
      postProductParams: payload,
      action: type,
    }),
    [CONST.POST_PRODUCTS_SUCCESS]: () => ({
      ...state,
      postProductFetch: false,
      postProductResponse: payload,
      action: type,
    }),
    [CONST.POST_PRODUCTS_FAILED]: () => ({
      ...state,
      postProductFetch: false,
      postProductError: payload,
      action: type,
    }),
    [CONST.UPDATE_PRODUCTS]: () => ({
      ...state,
      putProductFetch: false,
      putProductParams: payload,
      action: type,
    }),
    [CONST.UPDATE_PRODUCTS_SUCCESS]: () => ({
      ...state,
      putProductFetch: false,
      putProductResponse: payload,
      action: type,
    }),
    [CONST.UPDATE_PRODUCTS_FAILED]: () => ({
      ...state,
      putProductFetch: false,
      putProductError: payload,
      action: type,
    }),
    [CONST.DELETE_PRODUCTS]: () => ({
      ...state,
      deleteProductFetch: false,
      deleteProductParams: payload,
      action: type,
    }),
    [CONST.DELETE_PRODUCT_SUCCESS]: () => ({
      ...state,
      deleteProductFetch: false,
      deleteProductResponse: payload,
      action: type,
    }),
    [CONST.DELETE_PRODUCT_FAILED]: () => ({
      ...state,
      deleteProductFetch: false,
      deleteProductError: payload,
      action: type,
    }),
    [CONST.DELETE_PRODUCTS_ADD_ON]: () => ({
      ...state,
      deleteProductAddOnFetch: false,
      deleteProductAddOnParams: payload,
      action: type,
    }),
    [CONST.DELETE_PRODUCT_ADD_ON_SUCCESS]: () => ({
      ...state,
      deleteProductAddOnFetch: false,
      deleteProductAddOnResponse: payload,
      action: type,
    }),
    [CONST.DELETE_PRODUCT_ADD_ON_FAILED]: () => ({
      ...state,
      deleteProductAddOnFetch: false,
      deleteProductAddOnError: payload,
      action: type,
    }),
    [CONST.DETAIL_PRODUCTS]: () => ({
      ...state,
      detailProductFetch: false,
      detailProductParams: payload,
      action: type,
    }),
    [CONST.DETAIL_PRODUCT_SUCCESS]: () => ({
      ...state,
      detailProductFetch: false,
      detailProductResponse: payload,
      action: type,
    }),
    [CONST.DETAIL_PRODUCT_FAILED]: () => ({
      ...state,
      detailProductFetch: false,
      detailProductError: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageParams: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_SUCCESS]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageResponse: payload,
      action: type,
    }),
    [CONST.UPLOAD_IMAGE_FAILED]: () => ({
      ...state,
      uploadImageFetch: false,
      uploadImageError: payload,
      action: type,
    }),
    [CONST.SET_IMAGE_URL]: () => ({
      ...state,
      uploadImageFetch: false,
      icon: payload,
    }),

    [CONST.RESET_STATE]: () => ({
      ...productsInitialState,
      action: type,
    }),

    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default productsReducer;
