import { api } from '@myrepublic-bootstrap/bootstrapApi';

export const postUsers = (payload) => {
  const { accessToken, data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/users', data);
};

export const teknisiVendor = (payload) => {
  const { accessToken, data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post('/api/v1/web/teknisiVendor', data);
};

export const putUserStatus = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.put(`/api/v1/web/users/updateStatus/${data.id}`, data.payload);
};

export const createUser = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/users/create`, data);
};

export const updateUser = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.put(`/api/v1/web/users/update/${data.id}`, data.payload);
};

export const deleteUserPermission = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.delete(
    `/api/v1/web/userPermissions/delete/${data.id}`,
    data.payload
  );
};

export const getUserPermissionList = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/permissions/selectList`);
};

export const detailUser = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/users/detail/${payload.id}`);
};

export const uploadDataImage = (payload) => {
  const { accessToken, data } = payload;
  let formData = new FormData();
  formData.append('image', data.image);
  formData.append('type', data.type);
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/cdn/upload`, formData);
};

export const getUserRoles = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`/api/v1/web/roles`);
};

export const getTechnicianRating = (payload) => {
  const { accessToken, ...data } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.post(`/api/v1/web/users/technicianRates`, data);
};

export const getListVendorAPI = (payload) => {
  const { accessToken } = payload;
  api.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  return api.get(`api/v1/general/vendorList`);
};
