import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { postWorkOrder as postWorkOrderProps } from '@myrepublic-wokumo/wokumoAction';
import { DropzoneArea } from 'material-ui-dropzone';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

function CreateWorkOrder(props) {
  const { postWorkOrder, postWorkOrderResponse, postWorkOrderError } = props;
  const [data, setData] = useState({});
  //   const [description, setDescription] = useState({ value: '' });
  //   const [openErrorInfo, setOpenErrorInfo] = useState(false);
  //   const [errorInfo, setErrorInfo] = useState('');
  //   const [userPermissions, setUserPermissions] = useState({ data: [] });
  //   const [permissionOptions, setPermissionOptions] = useState([]);
  const history = useHistory();
  //   const [key, setKey] = useState(0);

  //   let closeToast = (event, reason) => {
  //     if (reason === 'clickaway') {
  //       return;
  //     }
  //     setOpenErrorInfo(false);
  //   };

  //   const dataVal = () => {
  //     const value = userPermissions.data;
  //     let data = [];
  //     if (typeof value === 'object') {
  //       data = value.map((userPermission, index) => {
  //         console.log('userPermission <<<< ', userPermission);
  //         if (userPermission.id === undefined) {
  //           return {
  //             permissionId: userPermission.permissionId,
  //           };
  //         }
  //         return {
  //           id: userPermission.id,
  //           permissionId: userPermission.permissionId,
  //         };
  //       });
  //       return values(data);
  //     }
  //   };

  //   const handleOpenErrorInfo = () => {
  //     setOpenErrorInfo(true);
  //   };

  //   const validateCreateForm = () => {
  //     let userPermissionList = dataVal();
  //     if (data?.permissionId === '') {
  //       setErrorInfo('Nama Permission harus diisi');
  //       handleOpenErrorInfo();
  //       return false;
  //     } else {
  //       userPermissionList.map((items) => {
  //         if (
  //           items.permissionId === undefined ||
  //           items.permissionId === 'undefined' ||
  //           items.permissionId === ''
  //         ) {
  //           setErrorInfo('Lengkapi semua field data pada permission list');
  //           handleOpenErrorInfo();
  //           return false;
  //         }
  //       });
  //     }
  //     return true;
  //   };
  const handleOnSave = () => {
    // if (validateCreateForm() === true) {
    //   const sendData = {
    //     ...data,
    //     userPermissionList: userPermissions.data,
    //   };
    console.log('SEND DATA', data);

    postWorkOrder({
      ...data,
    });
  };

  //   const onUploadImage = (image) => {
  //     if (image?.length > 0) {
  //       uploadImage({ data: { image: image[0], type: 'PROFILE' } });
  //     }
  //   };

  useEffect(() => {
    console.log('postWorkOrderResponse', postWorkOrderResponse);
    if (postWorkOrderResponse?.code === 200) {
      toast.success(postWorkOrderResponse?.message);
      setTimeout(() => {
        history.replace('/work-order');
      }, 3000);
    }
  }, [postWorkOrderResponse]);

  useEffect(() => {
    console.log('postWorkOrderError', postWorkOrderError);
  }, [postWorkOrderError]);

  return (
    <Card className="mx-4 card-box">
      <CardHeader>
        <CardTitle>
          <h5>
            <b>Create Work Order</b>
          </h5>
        </CardTitle>
      </CardHeader>
      <CardBody className="p-3">
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Customer Id</h6>
                <Form.Control
                  value={data?.customerId}
                  type="text"
                  placeholder="Customer Id"
                  onChange={(e) =>
                    setData({ ...data, customerId: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Description</h6>
                <Form.Control
                  value={data?.description}
                  type="text"
                  placeholder="Description"
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Service Type</h6>
                <Form.Control
                  value={data?.woServiceType}
                  type="text"
                  placeholder="Service Type"
                  onChange={(e) =>
                    setData({ ...data, woServiceType: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <h6>Ticket Category</h6>
                <Form.Control
                  value={data?.ticketCategory}
                  type="number"
                  placeholder="Ticket Category"
                  onChange={(e) =>
                    setData({ ...data, ticketCategory: Number(e.target.value) })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <h6>Vendor Id</h6>
                <Form.Control
                  value={data?.vendorId}
                  type="number"
                  placeholder="Vendor Id"
                  onChange={(e) =>
                    setData({ ...data, vendorId: Number(e.target.value) })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            className="bg-secondary"
            onClick={() => history.push('/work-order')}>
            Kembali
          </Button>
          <Button
            className="bg-primary"
            onClick={handleOnSave}
            disabled={false}>
            Simpan
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
const mapStateToProps = (state) => ({
  postWorkOrderResponse: state.wokumo.postWorkOrderResponse,
  postWorkOrderError: state.wokumo.postWorkOrderError,
});
const mapDispatchToProps = {
  postWorkOrder: (payload) => postWorkOrderProps(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkOrder);
