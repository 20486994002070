import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CreateIcon from '@material-ui/icons/Create';

const actions = [
  {
    name: 'update',
    icon: <CreateIcon />,
  },
  {
    name: 'delete',
    icon: <DeleteOutlineIcon />,
  },
];
export const columns = (toogleModal) => [
  {
    dataField: 'no',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1;
    },
  },
  {
    dataField: 'code',
    text: 'Kode',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'name',
    text: 'Nama',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'icon',
    text: 'Gambar',
    headerStyle: { width: '150px', textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <img
          src={row.icon}
          style={{
            height: 80,
            width: 80,
            backgroundSize: 'cover',
            background: 'no-repeat',
            imageResolution: 'low',
          }}
          alt="my-rep-img"
        />
      );
    },
  },
  {
    dataField: '#',
    text: 'Action',
    headerStyle: { width: '150px', textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <div className="container-button">
          {actions?.map((data, index) => (
            <div className="rounded" onClick={() => toogleModal(row, data)}>
              {data?.icon}
            </div>
          ))}
        </div>
      );
    },
  },
];
