import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import { Button } from 'reactstrap';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  getWorkOrder as getWorkOrderProps,
  resetState as resetStateProps,
} from '@myrepublic-wokumo/wokumoAction';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

// function OpenConfirm(props) {
//   const {
//     handleClose,
//     handleClickOpen,
//     open
//   } = props;

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description">
//         <DialogTitle id="alert-dialog-title">
//           {'Konfirmasi hapus data'}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Apakah anda yakin akan menghapus data?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Tidak
//           </Button>
//           <Button onClick={handleClickOpen} color="primary" autoFocus>
//             Ya
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }

function WorkOrder(props) {
  const {
    getWorkOrder,
    getWorkOrderResponse,
    pagination,
    PaginationRowHandler,
    PaginationHandler,
    getWorkOrderFetch,
    resetState,
  } = props;
  //   const [showCreateModal, setShowCreateModal] = useState(false);
  //   const [openModalDelete, setOpenModalDelete] = useState(false);
  //   const [openErrorInfo, setOpenErrorInfo] = useState(false);
  //   const [totalData, setTotalData] = useState();
  //   const [roleId, setRoleId] = useState();
  const [id, setid] = useState(0);
  const history = useHistory();

  const getDataTable = (_pagination = null) => {
    getWorkOrder({
      data: { ..._pagination },
    });
  };

  const handleActive = (row) => {
    // putUserStatus({id: row.id, payload: {active: !row.active}})
    console.log('row is', row);
  };

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    // resetState({});
    // console.log('handleEdit');
    // history.push('/update-customer/' + row?.id);
  };

  // const modalShowData = () => {
  //   history.push('/create-work-order');
  // };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
  }, []);

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => history.push('/create-work-order')}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <WrapContainerBased
        key={9999999999998}
        withTable
        withPagination
        totalRecord={getWorkOrderResponse?.totalElements || 0}
        totalPage={pagination.limit}
        wrapTitle={'Work Order edited'}
        data={getWorkOrderResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        withButton={buttonComponent()}
        handleChangePage={handleChangePage}
        showPerPage={1}
        isLoading={getWorkOrderFetch}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        {/* <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        /> */}
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  getWorkOrderResponse: state.wokumo.getWorkOrderResponse,
  getWorkOrderFetch: state.wokumo.getWorkOrderFetch,
});

const mapDispatchToProps = {
  getWorkOrder: (payload) => getWorkOrderProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
  resetState: (payload) => resetStateProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);
