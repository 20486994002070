import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import ClearIcon from '@material-ui/icons/Clear';
import StarIcon from '@material-ui/icons/Star';

export const columns = (
  onClickUpdateIcon,
  toogleDeleteConfirmModal,
  handleApprove,
  pagination
) => [
  {
    dataField: 'No',
    text: 'No',
    headerStyle: { width: '3vw' },
    style: { verticalAlign: 'middle' },
    formatter: (cell, row, rowIndex) => {
      return rowIndex + 1 + pagination?.offset * pagination?.limit;
    },
  },
  {
    dataField: 'name',
    text: 'Nama',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.name ?? 'text-center'}>{row?.name ?? '-'}</div>
      );
    },
  },
  {
    dataField: 'vendorName' || 'contractorName',
    text: 'Vendor',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div
          className={row?.vendorName ?? row?.contractorName ?? 'text-center'}>
          {row?.vendorName ?? row?.contractorName ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'username',
    text: 'Username',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.username ?? 'text-center'}>
          {row?.username ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'handphone',
    text: 'HP',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.handphone ?? 'text-center'}>
          {row?.handphone ?? '-'}
        </div>
      );
    },
  },
  {
    dataField: 'email',
    text: 'Email',
    headerStyle: { textAlign: 'center' },
    style: { verticalAlign: 'middle' },
    formatter: (_, row) => {
      return (
        <div className={row?.email ?? 'text-center'}>{row?.email ?? '-'}</div>
      );
    },
  },
  {
    dataField: '#',
    text: 'Action',
    headerStyle: { width: '150px', textAlign: 'center' },
    style: { verticalAlign: 'middle', textAlign: 'center' },
    formatter: (cell, row) => {
      return (
        <div className="container-button">
          <div className="rounded" onClick={() => onClickUpdateIcon(row)}>
            <CreateIcon />
          </div>
          {/* <div
            className="rounded"
            onClick={() => toogleDeleteConfirmModal(row)}>
            <DeleteOutlineIcon />
          </div> */}
          <div className="rounded" onClick={() => handleApprove(row)}>
            {row?.active ? <ClearIcon /> : <CheckIcon />}
          </div>
        </div>
      );
    },
  },
];
