import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Card, CardHeader, CardBody, CardTitle, Col, Row } from 'reactstrap';
import {
  updateUser as updateUserProps,
  detailUser as detailUserProps,
  uploadImage as uploadImageProps,
  deleteUserPermission as deleteUserPermissionProps,
  getUserPermissionList as getUserPermissionListProps,
  getUserRoles as getUserRolesProps,
  resetState as resetStateProps,
} from '@myrepublic-users/usersAction';
import { values } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Rating from './rating';

function DetailTeknisi(props) {
  const {
    updateUser,
    updateUserResponse,
    detailUser,
    detailUserResponse,
    getUserRoles,
    getUserRolesResponse,
    resetState,
    match: { params },
  } = props;
  const [data, setData] = useState({});
  const [userPermissions] = useState({ data: [] });
  const history = useHistory();
  const [roleId, setRoleId] = useState();

  //*  GET DATA USER, PERMISSION LIST, USER ROLE
  useEffect(() => {
    detailUser({ id: params?.id });
    getUserRoles();
    // eslint-disable-next-line
  }, []);

  // HANDLE SAVE EDIT
  const handleOnSave = () => {
    if (validateCreateForm() === true) {
      const sendData = {
        name: data.name,
        email: data.email,
        username: data.username,
        handphone: data.handphone,
        userPermissionList: dataVal(),
        devicePackageName: 'WEB',
        roleId: roleId,
      };
      updateUser({
        payload: sendData,
        id: data.id,
      });
    }
  };

  const validateCreateForm = () => {
    let userPermissionList = dataVal();
    if (data?.permissionId === '') {
      return false;
    } else {
      userPermissionList.map((items, index) => {
        if (
          items.permissionId === undefined ||
          items.permissionId === 'undefined' ||
          items.permissionId === ''
        ) {
          return false;
        }
      });
    }
    return true;
  };

  const dataVal = () => {
    const value = userPermissions.data;
    let data = [];
    if (typeof value === 'object') {
      data = value.map((userPermission, index) => {
        if (userPermission.id === undefined) {
          return {
            key: index,
            permissionId: userPermission.permissionId,
          };
        }
        return {
          key: index,
          id: userPermission.id,
          permissionId: userPermission.permissionId,
        };
      });
      return values(data);
    }
  };

  // UPDATE USER BERHASIL
  useEffect(() => {
    if (updateUserResponse?.code === 200) {
      toast.success(updateUserResponse?.message);
      history.replace('/teknisi-vendor');
    }
    return () => {
      resetState({});
    };
    // eslint-disable-next-line
  }, [updateUserResponse]);

  // GET USER PERMISSION
  useEffect(() => {
    if (detailUserResponse?.code === 200) {
      setData(detailUserResponse?.data?.user);
      detailUserResponse?.data?.user?.userPermissions?.map((i) => {
        return userPermissions?.data.push({
          key: i,
          permissionId: i.permission.id,
        });
      });
    }
    // eslint-disable-next-line
  }, [detailUserResponse?.code]);

  //*  GET USER ROLE ID FOR 'Technician'
  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex(
      (i) => i.name === 'Technician'
    );
    setRoleId(getUserRolesResponse?.data?.content[index]?.id);
  }, [getUserRolesResponse]);

  return (
    <>
      <Card className="mx-4 card-box">
        <CardHeader>
          <CardTitle>
            <h5>Detail Teknisi Vendor</h5>
          </CardTitle>
        </CardHeader>
        <CardBody className="p-3">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    value={data?.username}
                    type="text"
                    placeholder="Username"
                    onChange={(e) =>
                      setData({ ...data, username: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    value={data?.password}
                    disabled
                    type="password"
                    placeholder="Password"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    value={data?.name}
                    type="text"
                    placeholder="Nama"
                    disabled={false}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Handphone</Form.Label>
                  <Form.Control
                    value={data?.handphone}
                    type="text"
                    placeholder="Handphone"
                    onChange={(e) =>
                      setData({ ...data, handphone: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={data?.email}
                    type="email"
                    placeholder="Email"
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Rating technicianId={params?.id} handleOnSave={handleOnSave} />
    </>
  );
}
const mapStateToProps = (state) => ({
  updateUserResponse: state.user.updateUserResponse,
  detailUserResponse: state.user.detailUserResponse,
  getUserRolesResponse: state.user.getUserRolesResponse,
});
const mapDispatchToProps = {
  updateUser: (payload) => updateUserProps(payload),
  detailUser: (payload) => detailUserProps(payload),
  uploadImage: (payload) => uploadImageProps(payload),
  getUserRoles: (payload) => getUserRolesProps(payload),
  resetState: () => resetStateProps(),
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTeknisi);
