import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  // getVendor as getVendorProps,
  // putVendorStatus as putVendorStatusProps,
  resetState as resetStateProps,
  postVendorStatistics as postVendorStatisticsProps,
} from '@myrepublic-wokumo/wokumoAction';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { defaultPaging, defaultPaginationRowHandler } from 'src/utils';

function VendorMonitoring(props) {
  const {
    postVendorStatistics,
    postVendorStatisticsResponse,
    pagination,
    PaginationRowHandler,
    PaginationHandler,
    resetState,
    postVendorStatisticsFetch,
  } = props;
  const [id, setid] = useState(0);
  const history = useHistory();

  const getDataTable = (_pagination = null) => {
    postVendorStatistics({
      data: { ..._pagination, filter: { roleName: 'Vendor' } },
    });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  const handleActive = (row) => {
    // putVendorStatus({id: row.id, body: {statusActive: !row.statusActive}});
  };

  const handleDelete = (row) => {
    // setOpenModalDelete(true);
  };

  const handleAdd = () => {
    // resetState({});
    history.push('/create-vendor');
  };
  const handleEdit = (row) => {
    console.log('row is', row);
    history.push(`/update-vendor/${row.id}`);
  };

  const modalShowData = () => {
    history.push('/create-vendor');
  };

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
  }, []);

  return (
    <>
      <WrapContainerBased
        key={id}
        withTable
        withPagination
        totalRecord={postVendorStatisticsResponse?.totalElements || 0}
        totalPage={pagination.limit}
        wrapTitle={'Vendor Monitoring'}
        data={postVendorStatisticsResponse?.content}
        columns={columns(handleEdit, handleDelete, handleActive)}
        handleChangePage={handleChangePage}
        showPerPage={1}
        isLoading={postVendorStatisticsFetch}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
      ></WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postVendorStatisticsResponse: state.wokumo.postVendorStatisticsResponse,
  postVendorStatisticsFetch: state.wokumo.postVendorStatisticsFetch,
});

const mapDispatchToProps = {
  postVendorStatistics: (payload) => postVendorStatisticsProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
  resetState: (payload) => resetStateProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(VendorMonitoring);
