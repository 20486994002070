import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import useStyles from './styles';
import { Refresh } from '@material-ui/icons';
import { truncate, pushToArray } from '@myrepublic-utils';
import { Container } from 'react-bootstrap';

export default function TableInbox({ data, selected, setSelected, onRefresh }) {
  const classes = useStyles();

  const filterData = () => {
    let filteredData = [];
    let senderList = [];
    data?.map((i, index) => {
      // if (i?.title === 'Terhubung' && i?.sender?.role?.name !== 'Superadmin') {
      if (
        i?.recipient?.id === i?.sender?.id &&
        i?.sender?.role?.name !== 'Superadmin'
      ) {
        pushToArray(filteredData, {
          ...i,
          ...{
            senderId: i?.recipient?.id,
            senderName: i?.recipient?.name,
          },
          key: index,
        });
        senderList.push(i?.recipient?.id);
      }
    });
    return filteredData;
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <div className={classes.inboxTitleWrapper}>
                <Typography variant="h4">
                  <b>Inbox</b>
                </Typography>
                <IconButton onClick={onRefresh}>
                  <Refresh />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data === undefined || data === null ? (
            <Container className={classes.circularContainer}>
              <CircularProgress />
            </Container>
          ) : (
            filterData().map((data, index) => (
              <ChatTableRow
                key={index}
                data={data}
                selected={selected}
                setSelected={setSelected}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const ChatTableRow = ({ data, selected, setSelected }) => {
  const classes = useStyles();

  const checkMessageStatus = () => {
    if (data.id === selected) {
      return classes.selectedTableCell;
    } else if (data.read === true) {
      return classes.readTableCell;
    } else return null;
  };

  const styleTableCell = checkMessageStatus();

  return (
    <TableRow>
      <TableCell className={styleTableCell}>
        <div
          onClick={() => setSelected(data?.id, data?.senderId)}
          className={classes.button}>
          <Typography
            className={data?.id === selected ? classes.selectedText : null}>
            <b>{data?.senderName}</b>
          </Typography>
          <Typography
            className={data?.id === selected ? classes.selectedText : null}>
            {data.message === 'Initial Chat'
              ? 'Pesan baru'
              : truncate(data.message, 50)}
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};
