import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDebounce } from 'use-debounce';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'reactstrap';
import {
  postCSAgentChatMonitoring as postCSAgentChatMonitoringProps,
  postCSAgentForceLogout as postCSAgentForceLogoutProps,
  resetState as resetStateProps,
} from '@myrepublic-livechat/liveChatAction';

import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';

import { columns } from './column';
import { useHistory } from 'react-router';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Monitoring(props) {
  const {
    postCSAgentChatMonitoring,
    postCSAgentChatMonitoringResponse,
    postCSAgentForceLogout,
    postCSAgentForceLogoutResponse,
    resetState,
    PaginationHandler,
    PaginationRowHandler,
    postCSAgentChatMonitoringFetch,
  } = props;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [totalData, setTotalData] = useState();
  const [roleId, setRoleId] = useState();
  const history = useHistory();

  const getDataTable = (_pagination = null) => {
    postCSAgentChatMonitoring({ ..._pagination });
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };
  const handleOpenModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleForceLogout = (row) => {
    console.log('click', row);
    postCSAgentForceLogout({ csagentId: row?.csagentId });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  useEffect(() => {
    console.log(
      'postCSAgentChatMonitoringResponse',
      postCSAgentChatMonitoringResponse
    );
  }, [postCSAgentChatMonitoringResponse]);

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
  }, []);

  // useEffect(() => {
  //   getDataTable();
  // }, [postCSAgentForceLogoutResponse]);

  return (
    <>
      <WrapContainerBased
        withTable
        withPagination
        totalRecord={totalData}
        totalPage={totalData}
        wrapTitle={'Monitoring'}
        data={postCSAgentChatMonitoringResponse?.content}
        columns={columns(handleForceLogout)}
        handleChangePage={handleChangePage}
        showPerPage={1}
        isLoading={postCSAgentChatMonitoringFetch}
        // withButton={buttonComponent()}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  postCSAgentChatMonitoringResponse:
    state.livechat.postCSAgentChatMonitoringResponse,
  postCSAgentForceLogoutResponse: state.livechat.postCSAgentForceLogoutResponse,
  postCSAgentChatMonitoringFetch: state.livechat.postCSAgentChatMonitoringFetch,
});

const mapDispatchToProps = {
  postCSAgentChatMonitoring: (payload) =>
    postCSAgentChatMonitoringProps(payload),
  postCSAgentForceLogout: (payload) => postCSAgentForceLogoutProps(payload),
  resetState: (payload) => resetStateProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
