import * as CONST from '@myrepublic-troubleshooting/troubleShootingConstant';
import * as STATE from '@myrepublic-troubleshooting/troubleShootingInitialState';

const initialState = {
  ...STATE.troubleInitialState,
  action: '',
};

export const troubleReducer = (state = initialState, action) => {
  const { payload, type } = action;

  const actions = {
    [CONST.GET_DATA_TROUBLE]: () => ({
      ...state,
      getDataTroubleFetch: true,
      getDataTroubleParams: payload,
      action: type,
    }),
    [CONST.GET_DATA_TROUBLE_SUCCESS]: () => ({
      ...state,
      getDataTroubleFetch: false,
      getDataTroubleResponse: payload,
      action: type,
    }),
    [CONST.GET_DATA_TROUBLE_FAILED]: () => ({
      ...state,
      getDataTroubleFetch: false,
      getDataTroubleError: payload,
      action: type,
    }),
    [CONST.CREATE_DATA_TROUBLE]: () => ({
      ...state,
      createDataTroubleFetch: true,
      createDataTroubleParams: payload,
      action: type,
    }),
    [CONST.CREATE_DATA_TROUBLE_SUCCESS]: () => ({
      ...state,
      createDataTroubleFetch: false,
      createDataTroubleResponse: payload,
      action: type,
    }),
    [CONST.CREATE_DATA_TROUBLE_FAILED]: () => ({
      ...state,
      createDataTroubleFetch: false,
      createDataTroubleError: payload,
      action: type,
    }),

    [CONST.DELETE_DATA_TROUBLE]: () => ({
      ...state,
      deleteDataTroubleFetch: true,
      deleteDataTroubleParams: payload,
      action: type,
    }),
    [CONST.DELETE_DATA_TROUBLE_SUCCESS]: () => ({
      ...state,
      deleteDataTroubleFetch: false,
      deleteDataTroubleResponse: payload,
      action: type,
    }),
    [CONST.DELETE_DATA_TROUBLE_FAILED]: () => ({
      ...state,
      deleteDataTroubleFetch: false,
      deleteDataTroubleError: payload,
      action: type,
    }),

    [CONST.ANSWER_DATA_TROUBLE]: () => ({
      ...state,
      answerDataTroubleFetch: true,
      answerDataTroubleParams: payload,
      action: type,
    }),
    [CONST.ANSWER_DATA_TROUBLE_SUCCESS]: () => ({
      ...state,
      answerDataTroubleFetch: false,
      answerDataTroubleResponse: payload,
      action: type,
    }),
    [CONST.ANSWER_DATA_TROUBLE_FAILED]: () => ({
      ...state,
      answerDataTroubleFetch: false,
      answerDataTroubleError: payload,
      action: type,
    }),

    [CONST.PUT_DATA_TROUBLE]: () => ({
      ...state,
      putDataTroubleFetch: true,
      putDataTroubleParams: payload,
      action: type,
    }),
    [CONST.PUT_DATA_TROUBLE_SUCCESS]: () => ({
      ...state,
      putDataTroubleFetch: false,
      putDataTroubleResponse: payload,
      action: type,
    }),
    [CONST.PUT_DATA_TROUBLE_FAILED]: () => ({
      ...state,
      putDataTroubleFetch: false,
      putDataTroubleError: payload,
      action: type,
    }),
    [CONST.DELETE_ANSWER_DATA_TROUBLE]: () => ({
      ...state,
      deleteAnswerDataTroubleFetch: true,
      deleteAnswerDataTroubleParams: payload,
      action: type,
    }),
    [CONST.DELETE_ANSWER_DATA_TROUBLE_SUCCESS]: () => ({
      ...state,
      deleteAnswerDataTroubleFetch: false,
      deleteAnswerDataTroubleResponse: payload,
      action: type,
    }),
    [CONST.DELETE_ANSWER_DATA_TROUBLE_FAILED]: () => ({
      ...state,
      deleteAnswerDataTroubleFetch: false,
      deleteAnswerDataTroubleError: payload,
      action: type,
    }),
    [CONST.GET_ANSWER_TROUBLE]: () => ({
      ...state,
      getAnswerTroubleFetch: true,
      getAnswerTroubleParams: payload,
      action: type,
    }),
    [CONST.GET_ANSWER_TROUBLE_SUCCESS]: () => ({
      ...state,
      getAnswerTroubleFetch: false,
      getAnswerTroubleResponse: payload,
      action: type,
    }),
    [CONST.GET_ANSWER_TROUBLE_FAILED]: () => ({
      ...state,
      getAnswerTroubleFetch: false,
      getAnswerTroubleError: payload,
      action: type,
    }),

    [CONST.PUT_ANSWER_TROUBLE]: () => ({
      ...state,
      putAnswerTroubleFetch: true,
      putAnswerTroubleParams: payload,
      action: type,
    }),
    [CONST.PUT_ANSWER_TROUBLE_SUCCESS]: () => ({
      ...state,
      putAnswerTroubleFetch: false,
      putAnswerTroubleResponse: payload,
      action: type,
    }),
    [CONST.PUT_ANSWER_TROUBLE_FAILED]: () => ({
      ...state,
      putAnswerTroubleFetch: false,
      putAnswerTroubleError: payload,
      action: type,
    }),
    [CONST.RESET_ANSWER]: () => ({
      ...initialState,
      // getAnswerTroubleResponse: {},
      action: type,
    }),
    DEFAULT: () => state,
  };
  return (actions[type] || actions.DEFAULT)();
};

export default troubleReducer;
