import React from 'react';
import RS from 'react-select';

const Select = ({
  name,
  options,
  value,
  placeholder,
  isClearable,
  onChange,
  ...props
}) => {
  return (
    <RS
      options={options}
      value={value}
      onChange={onChange}
      isClearable={isClearable}
      name={name}
      placeholder={placeholder}
      styles={{
        valueContainer: (provided) => {
          return {
            ...provided,
            height: 'calc(1.5em + 1rem + 0.0625rem)',
            padding: '0.53rem 1.3rem',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.5',
          };
        },
      }}
      theme={(theme) => ({
        ...theme,
        borderWidth: 0,
        colors: {
          ...theme.colors,
          primary: 'var(--primary)',
        },
      })}
      {...props}
    />
  );
};

export default Select;
