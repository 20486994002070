import React from 'react';
import { Card } from 'react-bootstrap';
// import HandlerPaginationProps from '../CustomPagination';
import HandlerPaginationProps from '../CustomPagination';

function WrapperContainer(props) {
  const {
    children,
    wrapTitle,
    topContent,
    handleAddData,
    handleAddAnswer,
    withButton,
    totalRecord,
    totalPage,
    showPerPage,
    withPagination,
    handleChangePage,
    withFilterButton
  } = props;

  return (
    <>
      {topContent ? topContent : null}
      <Card className="mx-4 card-box">
        {wrapTitle && (
          <Card.Header className="d-flex align-items-center justify-content-between">
            <Card.Title>{wrapTitle}</Card.Title>
            {withFilterButton ? withFilterButton : null}
          </Card.Header>
        )}
        {withButton ? withButton : null}
        <Card.Body className="card-box">{children}</Card.Body>
        <Card.Footer>
          {withPagination && totalRecord > 0 && (
            <HandlerPaginationProps
              totalRecords={totalRecord}
              pageLimit={totalPage}
              showPerPage={showPerPage}
              handleChangePage={handleChangePage}
            />
          )}
        </Card.Footer>
      </Card>
    </>
  );
}

export default WrapperContainer;
