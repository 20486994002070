import React from 'react';
import Dashboard from '@myrepublic-dahsboard/page';
import Awards from '@myrepublic-awards/page';
import Products from '@myrepublic-products/page';
//import ProductsA from '@myrepublic-products/pageA';
import ProductsTv from '@myrepublic-products/page/Tv';
import StoryBoard from '@myrepublic-storyboard/page';
import TroubleShooting from '@myrepublic-troubleshooting/page';
import CreateProduct from '@myrepublic-products/page/create';
import UpdateProduct from '@myrepublic-products/page/update';
import CreateProductTv from '@myrepublic-products/page/Tv/create';
import UpdateProductTv from '@myrepublic-products/page/Tv/update';
import LiveChat from '@myrepublic-livechat/page';
import Customer from '@myrepublic-users/page/Customer';
import Teknisi from '@myrepublic-users/page/Teknisi';
import TeknisiVendor from '@myrepublic-users/page/TeknisiVendor';
import SuperAdmin from '@myrepublic-users/page/SuperAdmin';
import UpdateSuperadmin from '@myrepublic-users/page/SuperAdmin/update';
import CreateSuperadmin from '@myrepublic-users/page/SuperAdmin/create';
import CustomerJourney from '@myrepublic-users/page/CustomerJourney';
import UpdateCustomerJourney from '@myrepublic-users/page/CustomerJourney/update';
import DetailCustomerJourney from '@myrepublic-users/page/CustomerJourney/detail';
import CreateCustomerJourney from '@myrepublic-users/page/CustomerJourney/create';
import UpdateCustomer from '@myrepublic-users/page/Customer/update';
import SettingLiveChat from '@myrepublic-livechat/page/Setting';
import UpdateLivechatSetting from '@myrepublic-livechat/page/Setting/update';
import Statistik from '@myrepublic-livechat/page/Statistik';
import Vendor from '@myrepublic-wokumo/page/Vendor';
import DetailVendor from '@myrepublic-wokumo/page/Vendor/detail';
import WorkOrder from '@myrepublic-wokumo/page/WorkOrder';
import CreateWorkOrder from '@myrepublic-wokumo/page/WorkOrder/create';
import Monitoring from '@myrepublic-livechat/page/Monitoring';
import History from '@myrepublic-livechat/page/History';
import CreateVendor from '@myrepublic-wokumo/page/Vendor/create';
import DetailTeknisi from '@myrepublic-users/page/Teknisi/detail';
import DetailTeknisiVendor from '@myrepublic-users/page/TeknisiVendor/detail';
import VendorMonitoring from '@myrepublic-wokumo/page/VendorMonitoring';
// const Dashboard = React.lazy(() => import('@myrepublic-dahsboard/'));
// const Awards = React.lazy(() => import('@myrepublic-awards/'));
// const Products = React.lazy(() => import('@myrepublic-products/'));

const routes = [
  {
    path: '/',
    exact: true,
    component: Dashboard,
    name: 'Dashboard',
    access: '',
  },
  {
    path: '/awards',
    exact: true,
    component: Awards,
    name: 'Awards',
    access: '/api/v1/web/awards_READ',
  },
  {
    path: '/products',
    exact: true,
    component: Products,
    name: 'Product',
    access: '/api/v1/web/addOnProducts_READ',
  },
  {
    path: '/productsTv',
    exact: true,
    component: ProductsTv,
    name: 'ProductTv',
    access: '/api/v1/web/addOnTv_READ',
  },
  {
    path: '/create-product',
    exact: true,
    component: CreateProduct,
    name: 'CreateProduct',
    access: '/api/v1/web/addOnProducts_CREATE',
  },
  {
    path: '/update-product/:id',
    exact: true,
    component: UpdateProduct,
    name: 'UpdateProduct',
    access: '/api/v1/web/addOnProducts_UPDATE',
  },

  {
    path: '/create-producttv',
    exact: true,
    component: CreateProductTv,
    name: 'CreateProductTv',
    access: '/api/v1/addOnProducts_CREATE',
  },
  {
    path: '/update-producttv/:id',
    exact: true,
    component: UpdateProductTv,
    name: 'UpdateProductTv',
    access: '/api/v1/web/addOnProducts_UPDATE',
  },

  {
    path: '/storyboard',
    exact: true,
    component: StoryBoard,
    name: 'Storyboard',
    access: '',
  },
  {
    path: '/troubleshooting',
    exact: true,
    component: TroubleShooting,
    name: 'TroubleShooting',
    access: '/api/v1/web/troubleshootings_READ',
  },
  {
    path: '/livechat',
    exact: true,
    component: LiveChat,
    name: 'LiveChat',
    access: '/api/v1/web/chat_READ',
  },
  {
    path: '/livechat-setting',
    exact: true,
    component: SettingLiveChat,
    name: 'Setting LiveChat',
    access: '/api/v1/web/chatSetting_READ',
  },
  {
    path: '/livechat-statistik',
    exact: true,
    component: Statistik,
    name: 'Statistik LiveChat',
    access: '/api/v1/web/chat_READ',
  },
  {
    path: '/livechat-statistik/:id',
    exact: true,
    component: Statistik,
    name: 'Statistik LiveChat',
    access: '/api/v1/web/chat_READ',
  },
  {
    path: '/livechat-monitoring',
    exact: true,
    component: Monitoring,
    name: 'Monitoring LiveChat',
    access: '/api/v1/web/chat_READ',
  },
  {
    path: '/livechat-history',
    exact: true,
    component: History,
    name: 'History LiveChat',
    access: '/api/v1/web/chat_READ',
  },
  {
    path: '/livechat-history/:id',
    exact: true,
    component: History,
    name: 'History LiveChat',
    access: '/api/v1/web/chat_READ',
  },

  {
    path: '/update-livechat-setting/:id',
    exact: true,
    component: UpdateLivechatSetting,
    name: 'Setting LiveChat',
    access: '/api/v1/web/chatSetting_READ',
  },
  {
    path: '/customers',
    exact: true,
    component: Customer,
    name: 'Customers',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/update-customer/:id',
    exact: true,
    component: UpdateCustomerJourney,
    name: 'UserPermissions',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/teknisi',
    exact: true,
    component: Teknisi,
    name: 'Teknisi',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/detail-teknisi/:id',
    exact: true,
    component: DetailTeknisi,
    name: 'Detail Teknisi',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/superadmin',
    exact: true,
    component: SuperAdmin,
    name: 'Super Admin',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/update-superadmin/:id',
    exact: true,
    component: UpdateSuperadmin,
    name: 'UpdateSuperadmin',
    access: '/api/v1/web/users_UPDATE',
  },
  {
    path: '/create-superadmin',
    exact: true,
    component: CreateSuperadmin,
    name: 'UpdateSuperadmin',
    access: '/api/v1/web/users_UPDATE',
  },
  {
    path: '/customer-journey',
    exact: true,
    component: CustomerJourney,
    name: 'Customer Journey',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/update-customer-journey/:id',
    exact: true,
    component: UpdateCustomerJourney,
    name: 'Update Customer Journey',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/detail-customer-journey/:id',
    exact: true,
    component: DetailCustomerJourney,
    name: 'Detail Customer Journey',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/create-customer-journey',
    exact: true,
    component: CreateCustomerJourney,
    name: 'Create Customer Journey',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/user-permissions',
    exact: true,
    component: Customer,
    name: 'UserPermissions',
    access: '/api/v1/web/users_READ',
  },
  {
    path: '/vendor',
    exact: true,
    component: Vendor,
    name: 'Vendor',
    access: '/api/v1/web/vendors_READ',
  },
  {
    path: '/create-vendor',
    exact: true,
    component: CreateVendor,
    name: 'Create Vendor',
    access: '/api/v1/web/vendors_READ',
  },
  {
    path: '/monitoring-vendor',
    exact: true,
    component: VendorMonitoring,
    name: 'Vendor Monitoring',
    access: '/api/v1/web/vendors_READ',
  },
  {
    path: '/detail-vendor/:id',
    exact: true,
    component: DetailVendor,
    name: 'Detail Vendor',
    access: '/api/v1/web/vendors_READ',
  },
  {
    path: '/work-order',
    exact: true,
    component: WorkOrder,
    name: 'Work Order',
    access: '/api/v1/web/vendors_READ',
  },
  {
    path: '/create-work-order',
    exact: true,
    component: CreateWorkOrder,
    name: 'Create Work Order',
    access: '/api/v1/web/vendors_READ',
  },
  {
    path: '/teknisi-vendor',
    exact: true,
    component: TeknisiVendor,
    name: 'Teknisi Vendor',
    access: '/api/v1/web/teknisiVendor_READ',
  },
  {
    path: '/detail-teknisi-vendor/:id',
    exact: true,
    component: DetailTeknisiVendor,
    name: 'Detail Teknisi Vendor',
    access: '/api/v1/web/users_READ',
  },
];

export default routes;
