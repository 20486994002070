import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import WrapContainerBased from '@myrepublic-components/DataTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from 'reactstrap';
import {
  postUsers as postUsersProps,
  putUserStatus as putUserStatusProps,
  getUserRoles as getUserRolesProps,
  resetState as resetStateProps,
  postUsersReset as postUsersResetProps,
} from '@myrepublic-users/usersAction';
import { columns } from './column';
import { useHistory } from 'react-router';
import {
  PaginationRowHandler as PaginationRowHandlerProps,
  PaginationHandler as PaginationHandlerProps,
} from '@myrepublic-bootstrap/bootstrapAction';
import { defaultPaginationRowHandler, defaultPaging } from 'src/utils';

function OpenConfirm(props) {
  const { handleClose, handleClickOpen, open } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Konfirmasi hapus data'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin akan menghapus data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Tidak
          </Button>
          <Button onClick={handleClickOpen} color="primary" autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function SuperAdmin(props) {
  const {
    pagination,
    postUsers,
    postUsersReset,
    postUsersResponse,
    putUserStatus,
    putUserStatusResponse,
    resetState,
    getUserRoles,
    getUserRolesResponse,
    PaginationRowHandler,
    PaginationHandler,
    postUsersFetch,
  } = props;
  // const [showCreateModal, setShowCreateModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  // const [openErrorInfo, setOpenErrorInfo] = useState(false);
  const [roleId, setRoleId] = useState();
  const [id, setid] = useState(0);
  const history = useHistory();

  const [editParam, setEditParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });

  const [deleteParam, setDeleteParam] = useState({
    name: '',
    code: '',
    icon: '',
    description: '',
    addOnItemListName: '',
    addOnItemListPrice: '',
    tv: false,
  });
  const getDataTable = (_pagination = null) => {
    postUsers({
      data: { ..._pagination, filter: { roleName: 'Superadmin' } },
    });
  };

  const handleChangePage = (_pagination) => {
    getDataTable(_pagination);
  };

  // const handleOpenErrorInfo = () => {
  //   setOpenErrorInfo(true);
  // };

  // const handleClose = () => {
  //   setShowCreateModal(false);
  // };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  const handleOpenModalDelete = () => {
    // deleteProduct({ id: deleteParam?.data?.id });
    setOpenModalDelete(false);
  };

  const handleDelete = (row) => {
    setOpenModalDelete(true);
  };
  const handleEdit = (row) => {
    resetState({});

    setEditParam({
      password: row?.password,
      name: row?.password,
      roleId: roleId,
      profileImageUrl: row?.profileImageUrl,
      devicePackageName: 'WEB',
      userPermissionList: row?.userPermissions,
    });
    history.push('/update-superadmin/' + row?.id);
  };

  const handleActive = (row) => {
    putUserStatus({ id: row.id, payload: { active: !row.active } });
  };

  const modalShowData = () => {
    // setShowCreateModal(true);
    history.push('/create-superadmin/');
  };

  const buttonComponent = () => {
    return (
      <>
        <div className="d-flex flex-row-reverse justify-content-sp mt-2 px-3 gap-3">
          <Button variant="outline-primary" size="sm" onClick={modalShowData}>
            {' '}
            Tambah Data
          </Button>
        </div>
      </>
    );
  };

  useEffect(() => {
    const index = getUserRolesResponse?.data?.content?.findIndex(
      (i) => i.name === 'Superadmin'
    );
    setRoleId(getUserRolesResponse?.data?.content[index]?.id);
  }, [getUserRolesResponse]);

  useEffect(() => {
    resetState();
    PaginationHandler(defaultPaging);
    PaginationRowHandler(defaultPaginationRowHandler);
    getDataTable(defaultPaging);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WrapContainerBased
        key={id}
        withTable
        withPagination
        totalRecord={postUsersResponse?.data?.totalElements || 0}
        totalPage={pagination.limit}
        data={postUsersResponse?.data?.content}
        wrapTitle={'Superadmin'}
        columns={columns(handleEdit, handleDelete, handleActive)}
        withButton={buttonComponent()}
        handleChangePage={handleChangePage}
        showPerPage={1}
        isLoading={postUsersFetch}
        // handleAddData={modalShowData}
        // handleAddAnswer={() => setShowModalAnswer(!showModalAnswer)}
      >
        <OpenConfirm
          handleClose={handleCloseModalDelete}
          handleClickOpen={handleOpenModalDelete}
          open={openModalDelete}
        />
      </WrapContainerBased>
    </>
  );
}

const mapStateToProps = (state) => ({
  pagination: state.paging.pagination,
  postUsersResponse: state.user.postUsersResponse,
  putUserStatusResponse: state.user.putUserStatusResponse,
  getUserRolesResponse: state.user.getUserRolesResponse,
  postUsersFetch: state.user.postUsersFetch,
});

const mapDispatchToProps = {
  postUsers: (payload) => postUsersProps(payload),
  postUsersReset: (payload) => postUsersResetProps(payload),
  putUserStatus: (payload) => putUserStatusProps(payload),
  resetState: () => resetStateProps(),
  getUserRoles: (payload) => getUserRolesProps(payload),
  PaginationHandler: (payload) => PaginationHandlerProps(payload),
  PaginationRowHandler: (payload) => PaginationRowHandlerProps(payload),
};
export default connect(mapStateToProps, mapDispatchToProps)(SuperAdmin);
